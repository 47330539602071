import { useTreeItem2 } from '@mui/x-tree-view'
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2'
import { FC, forwardRef } from 'react'
import CustomLabel from './TreeLabel'

const CustomItem: FC<TreeItem2Props> = forwardRef(({ ...props }, ref) => {
  const { id, itemId, label, disabled, children } = props
  const {
    publicAPI,
    //@ts-ignore
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref })

  //@ts-ignore
  const { id: doc_id, deleted, showOptions, icon } = publicAPI.getItem(itemId)
  return (
    <TreeItem2
      {...props}
      label={
        <CustomLabel
          doc_id={doc_id}
          deleted={deleted}
          showOptions={showOptions}
          icon={icon}
        >
          {props.label}
        </CustomLabel>
      }
    />
  )
})

export default CustomItem
