import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { FC } from 'react'
import { useParams } from 'react-router'
import Grid from '../common/Grid'
import GridContainer from '../common/GridContainer'
import Loader from '../Loader'
import useDutiesApi from './api'

const cols: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 250,
    cellClassName: 'small-cell',
  },
  {
    field: 'run_by',
    headerName: 'TRIGGERED BY',
    minWidth: 200,
    cellClassName: 'small-cell',
  },
]

const History: FC = () => {
  const params = useParams<{ dutyId: string }>()

  const { getDutyHistory } = useDutiesApi()

  const { data, isLoading, isError } = useQuery({
    queryKey: ['duty-history'],
    queryFn: () => getDutyHistory(params.dutyId || ''),
    enabled: Boolean(params.dutyId),
  })

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <Typography variant="subtitle1">Error occured</Typography>
  }

  return (
    <GridContainer>
      <Grid columns={cols} rows={data} />
    </GridContainer>
  )
}

export default History
