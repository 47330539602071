import { PartialBlock } from '@blocknote/core'
import { useEffect, useState } from 'react'

const useAutoSave = (
  isEdit: boolean,
  editor: any,
  save: any,
  label?: any,
  keywords?: any,
  attendees?: any
) => {
  const [blocks, setBlocks] = useState<PartialBlock[]>(editor.document)

  // Auto save when moves out of page
  useEffect(() => {
    return () => {
      if (isEdit) {
        save(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        isEdit &&
        ((editor && editor.document) || label || keywords || attendees)
      ) {
        console.log('Auto Saving')
        save(true)
      }
    }, 3000) // Save after 3 second of inactivity

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, blocks, label, keywords, attendees])

  return [setBlocks] as const
}

export default useAutoSave
