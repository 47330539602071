// ToastContext.tsx
import { AlertColor } from '@mui/material'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

// Define the type for the context value

type ShowToast = (message: string, severity?: AlertColor) => void

interface ToastContextProps {
  showToast: ShowToast
}

// Define the type for the state of the toast
interface ToastState {
  open: boolean
  message: string
  severity: AlertColor
}

// Create the context with a default value
const ToastContext = createContext<ToastContextProps | undefined>(undefined)

export const promiseToaster =
  <Args extends any[], T>(
    asyncFn: (...args: Args) => Promise<T>,
    successMessage: string = 'Success!',
    errorMessage: string = 'An error occurred.',
    showToast: ShowToast
  ) =>
  async (...args: Args): Promise<T> => {
    const show = args.pop() // Remove and store the last argument (show)
    try {
      const result = await asyncFn(...args)
      if (successMessage && show) {
        showToast(successMessage, 'success')
      }
      return result
    } catch (error) {
      if (errorMessage && show) {
        showToast(errorMessage, 'error')
      }
      console.error(errorMessage, error)
      throw error
    }
  }

// Hook to use the toast context
export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

// Props for the ToastProvider
interface ToastProviderProps {
  children: ReactNode
}

// ToastProvider component
const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<ToastState>({
    open: false,
    message: '',
    severity: 'info',
  })

  // Function to show the toast
  const showToast = useCallback(
    (message: string, severity: AlertColor = 'info') => {
      setToast({
        open: true,
        message,
        severity,
      })
    },
    []
  )

  // Function to close the toast
  const closeToast = () => {
    setToast((prev) => ({ ...prev, open: false }))
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Snackbar
        open={toast.open}
        autoHideDuration={5000} // Set auto-close duration to 5 seconds
        onClose={closeToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={closeToast}
          severity={toast.severity}
          sx={{ width: '100%' }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  )
}

export default ToastProvider
