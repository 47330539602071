import Box from '@mui/material/Box'
import { useQuery } from '@tanstack/react-query'
import { Edge, Node, useEdgesState, useNodesState } from '@xyflow/react'
import { FC, useEffect } from 'react'
import useOrg from '../../hooks/useOrg'
import { useBackgroundModal } from '../../providers/BackgroundModal'
import JSONCanvas from '../JSONCanvas'
import useHomeCanvasApi from './api'

const Home: FC = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([] as Node[])
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge[])

  const { slug } = useOrg()
  const { getCanvas } = useHomeCanvasApi()
  const { setRoot } = useBackgroundModal()

  const { data } = useQuery({
    queryKey: ['canvas', slug],
    queryFn: getCanvas,
  })

  useEffect(() => {
    setRoot('')
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data) {
      setNodes([...data.nodes])
      setEdges([...data.edges])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Box sx={{ height: '100vh' }}>
      <JSONCanvas
        customBackground
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
      />
    </Box>
  )
}

export default Home
