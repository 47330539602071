import { CanvasColor, CanvasEdge, CanvasNode } from './typing'

export const getColor = (color: CanvasColor): string => {
  const presetColors: Record<string, string> = {
    '1': '#ff0000',
    '2': '#ff9900',
    '3': '#ffff00',
    '4': '#00ff00',
    '5': '#00ffff',
    '6': '#9900ff',
  }
  return presetColors[color] || color
}

export const edgeMapper = (edge: CanvasEdge) => ({
  ...edge,
  source: edge.fromNode,
  target: edge.toNode,
  sourceHandle: `${edge.fromNode}-source-${edge.fromSide}`,
  targetHandle: `${edge.toNode}-target-${edge.toSide}`,
  type: 'smoothstep',
  pathOptions: { borderRadius: 12 },
  markerEnd: edge.toEnd === 'arrow' ? 'arrow' : undefined,
  markerStart: edge.fromEnd === 'arrow' ? 'arrow' : undefined,
  style: edge.color ? { stroke: getColor(edge.color) } : undefined,
  labelStyle: { fill: edge.color ? getColor(edge.color) : undefined },
})

export const nodeMapper = (node: CanvasNode) => ({
  ...node,
  position: { x: node.x, y: node.y },
  type: node.type as string,
  style: { width: node.width, height: node.height },
  data: {
    ...node,
    label: node.label,
    color: node.color,
    description: node.description,
    url: node.url,
  },
})
