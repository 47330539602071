import {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  MiniMap,
  Node,
  ReactFlow,
  ReactFlowProps,
  ReactFlowProvider,
  useReactFlow,
} from '@xyflow/react'
import { FC, useEffect } from 'react'
import CustomBackground from '../Workflow/Core/CustomBackground'
import { FileNode, GroupNode, LinkNode, TextNode } from './Nodes'

const nodeTypes = {
  text: TextNode,
  file: FileNode,
  link: LinkNode,
  group: GroupNode,
}

interface JSONCanvasDisplayProps extends ReactFlowProps<Node, Edge> {
  customBackground?: boolean
  showMap?: boolean
  showControls?: boolean
  viewHeight?: number | string
}

const JSONCanvasView: FC<JSONCanvasDisplayProps> = ({
  customBackground,
  showMap = true,
  showControls = true,
  viewHeight,
  ...props
}) => {
  const { fitView } = useReactFlow()

  useEffect(() => {
    fitView({ duration: 250 })
    // eslint-disable-next-line
  }, [props.nodes, viewHeight])

  return (
    <ReactFlow {...props} nodeTypes={nodeTypes} fitView>
      {customBackground ? (
        <CustomBackground variant={BackgroundVariant.Dots} size={2} gap={140} />
      ) : (
        <Background variant={BackgroundVariant.Dots} size={4} gap={120} />
      )}
      {showControls && <Controls />}
      {showMap && <MiniMap />}
    </ReactFlow>
  )
}

const JSONCanvas: FC<JSONCanvasDisplayProps> = (props) => {
  return (
    <ReactFlowProvider>
      <JSONCanvasView {...props} />
    </ReactFlowProvider>
  )
}

export default JSONCanvas
