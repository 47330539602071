import Menu from '@mui/material/Menu'
import MenuList from '@mui/material/MenuList'
import { FC, PropsWithChildren } from 'react'
import { ContextMenuProps } from './typing'

const ContextMenu: FC<PropsWithChildren<ContextMenuProps>> = ({
  contextMenuAnchor,
  onClose,
  children,
}) => {
  return (
    <Menu
      open={contextMenuAnchor !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuAnchor !== null
          ? { top: contextMenuAnchor.mouseY, left: contextMenuAnchor.mouseX }
          : undefined
      }
    >
      <MenuList dense>{children}</MenuList>
    </Menu>
  )
}

export default ContextMenu
