import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import TableEditor from '../TableEditor'

const Outreach = () => (
  <Box>
    <TableEditor
      readonly
      tableName="demo_care_gaps"
      hideHeader
      columOverrides={[
        {
          field: 'play',
          headerName: 'Outreach Campaigns',
          renderCell: (params) => {
            if (params.row.id === '17bcbb46-ae3b-4f1e-accf-60ca1111c994') {
              return ''
            }
            return (
              <MuiLink
                sx={{
                  textTransform: 'none',
                  borderRadius: '24px',
                }}
                color="secondary"
                size="small"
                variant="contained"
                component={Link}
                to={(params.row.outreach_type || 'Mammogram').replaceAll(
                  ' ',
                  '-'
                )}
              >
                Begin Outreach
              </MuiLink>
            )
          },
        },
        {
          field: 'patients',
          renderCell: (params) => {
            if (params.row.id === '17bcbb46-ae3b-4f1e-accf-60ca1111c994') {
              return (
                <Typography
                  variant="subtitle3"
                  sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  {params.row[params.field]}
                </Typography>
              )
            }
            return params.row[params.field]
          },
        },
        {
          field: 'revenue',
          headerName: 'Potential Revenue',
          renderCell: (params) => {
            if (params.row.id === '17bcbb46-ae3b-4f1e-accf-60ca1111c994') {
              return (
                <Typography
                  variant="subtitle3"
                  sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  {params.row[params.field]}
                </Typography>
              )
            }
            return params.row[params.field]
          },
        },
        {
          field: 'outreach_type',
          renderCell: (params) => {
            if (params.row.id === '17bcbb46-ae3b-4f1e-accf-60ca1111c994') {
              return (
                <Typography
                  variant="subtitle3"
                  sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  {params.row[params.field]}
                </Typography>
              )
            }
            return params.row[params.field]
          },
        },
      ]}
    />
  </Box>
)
export default Outreach
