import { useApi } from '../../providers/ApiProvider'

const API_BASE_URL = '/api/prompt/'

export interface PromptScript {
  prompt: string
  prompt_name: string
  id: string
  created_at: string
}

const usePromptScriptApi = () => {
  const api = useApi()

  const getPromptScripts = async (key: string) => {
    const data = await api.get<PromptScript[]>(`${API_BASE_URL}${key}`)
    return data.data
  }

  const getPromptScriptsDetail = async (key: string, id: string) => {
    const data = await api.get<PromptScript>(`${API_BASE_URL}${key}/${id}`)
    return data.data
  }

  const updatePrompt = async (key: string, id: string, prompt: string) => {
    const data = await api.patch<PromptScript>(`${API_BASE_URL}${key}/${id}`, {
      prompt: prompt,
    })
    return data.data
  }

  return {
    getPromptScripts,
    getPromptScriptsDetail,
    updatePrompt,
  }
}

export default usePromptScriptApi
