import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import AudioPlayer from '../../../CallDetail/AudioPlayer'

const Audio = () => {
  return (
    <Paper variant="elevation" elevation={4} sx={{ p: 2, width: 300 }}>
      <Typography variant="h6" sx={{ pb: 1 }}>
        Call Audio
      </Typography>
      <AudioPlayer
        audioBlob={
          'https://storage.googleapis.com/helpcare_website_audio_files/En-Cervical-PAP-Call.wav' as any
        }
      />
    </Paper>
  )
}

export default Audio
