import { useAuth, useOrganization } from '@clerk/clerk-react'
import Assessment from '@mui/icons-material/Assessment'
import Call from '@mui/icons-material/Call'
import DeleteForever from '@mui/icons-material/DeleteForever'
import HighQuality from '@mui/icons-material/HighQuality'
import PendingActions from '@mui/icons-material/PendingActions'
import SchemaOutlined from '@mui/icons-material/SchemaOutlined'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useOrg from '../../hooks/useOrg'
import MyMeetings from '../icons/MyMeetings'
import useMeetingsApi from '../Meetings/api'
import useReportsApi from '../MyData/Reports/api'
import useWorkflowApi from '../Workflow/api'
import usePagesApi, { Page } from './api'

const myCalls = [
  {
    id: 'call-logs',
    url: 'call-logs',
    label: 'All Calls',
    icon: (
      <Call sx={{ mr: 1, color: 'text.secondary', height: 20, width: 20 }} />
    ),
  },
]

const recursiveMapPage = (page: Page[]): any[] => {
  return page.map((e) => ({
    id: e.id,
    label: e.label,
    url: `/pages/${e.id}`,
    children: e.children?.length ? recursiveMapPage(e.children) : [],
    showOptions: true,
  }))
}

export const useTreeData = () => {
  const { has } = useAuth()
  const { getMyMeetings } = useMeetingsApi()
  const { getAllReports } = useReportsApi()
  const { organization } = useOrganization()
  const { isHelpcareHq } = useOrg()
  const { getAllPages } = usePagesApi()
  const { data } = useQuery({
    queryKey: ['my-meetings'],
    queryFn: () => getMyMeetings(),
    enabled: organization !== null,
  })

  const { data: reports } = useQuery({
    queryKey: ['my-reports'],
    queryFn: () => getAllReports(),
    enabled: organization !== null,
  })

  const showMyData = useMemo(
    () =>
      (reports || [])?.length > 0 &&
      has &&
      has({ permission: 'org:feature:read_data_view' }),
    [reports, has]
  )

  const myData = useMemo(() => {
    return showMyData
      ? [
          {
            id: 'org-data',
            url: '/my-data',
            label: 'My Data',
            icon: (
              <Assessment
                fontSize="small"
                sx={{ mr: 1, color: 'text.secondary' }}
              />
            ),
            children: (reports || []).map((e) => {
              return {
                id: e.id,
                url: `/my-data/${e.id}`,
                label: e.title,
                showOptions: false,
              }
            }),
          },
        ]
      : []
  }, [showMyData, reports])

  const helpcareHQ = useMemo(
    () =>
      isHelpcareHq
        ? [
            {
              id: 'hc-hq-root',
              url: '/meetings',
              label: 'Helpcare HQ',
              icon: (
                <HighQuality
                  fontSize="small"
                  sx={{ mr: 1, color: 'text.secondary' }}
                />
              ),
              children: [
                {
                  id: 'meetings',
                  url: '/meetings',
                  label: 'Meetings',
                  showOptions: false,
                },
              ],
            },
          ]
        : [],
    [isHelpcareHq]
  )
  const myMeetings = useMemo(
    () =>
      organization !== null
        ? [
            ...helpcareHQ,
            {
              id: 'my-meetings',
              url: '/my-meetings',
              label: 'My Meetings',
              icon: (
                <MyMeetings
                  height={20}
                  width={20}
                  sx={{ mr: 1, color: 'text.secondary' }}
                />
              ),
              children: (data || []).map((e) => ({
                id: e.id,
                url: `/my-meetings/${e.id}`,
                label: e.meta.label,
                showOptions: false,
              })),
            },
            ...myData,
          ]
        : [],
    [data, organization, helpcareHQ, myData]
  )

  const { data: activePages } = useQuery({
    queryKey: ['pages', organization?.id],
    queryFn: () => getAllPages(false),
  })

  const treeData = useMemo(() => {
    return [
      {
        id: 'actions',
        url: 'actions',
        label: 'My Actions',
        icon: (
          <PendingActions
            sx={{ mr: 1, color: 'text.secondary', height: 20, width: 20 }}
          />
        ),
      },
      ...myMeetings,
      ...myCalls,
      activePages?.length
        ? {
            id: 'my-pages',
            label: 'Knowledge base',
            icon: (
              <MyMeetings
                height={20}
                width={20}
                sx={{ mr: 1, color: 'text.secondary' }}
              />
            ),
            children: recursiveMapPage(activePages || []),
          }
        : null,
    ].filter((e) => e != null)
  }, [myMeetings, activePages])

  return treeData
}

export const useWorkflowData = () => {
  const { organization } = useOrganization()
  const { workflow } = useWorkflowApi()

  const { data: workflows } = useQuery({
    queryKey: ['workflow', organization?.id],
    queryFn: () => workflow.getAll(),
  })

  const workflowData = useMemo(() => {
    return workflows?.length
      ? [
          {
            id: 'workflow-root',
            label: 'Workflow',
            icon: (
              <SchemaOutlined
                height={20}
                width={20}
                sx={{ mr: 1, color: 'text.secondary' }}
              />
            ),
            children: workflows.map((e: any) => ({
              id: e.id,
              label: e.name,
              url: `/workflows/${e.id}`,
              showOptions: false,
            })),
          },
        ]
      : []
  }, [workflows])

  return workflowData
}

export const useTrashData = () => {
  const { organization } = useOrganization()
  const { getAllPages } = usePagesApi()

  const { data: deletedPages } = useQuery({
    queryKey: ['pages-trash', organization?.id],
    queryFn: () => getAllPages(true),
  })

  const trashData = useMemo(() => {
    return deletedPages?.length
      ? [
          {
            id: 'trash-root',
            label: 'Trash',
            icon: (
              <DeleteForever
                sx={{ mr: 1, color: 'text.secondary', height: 20, width: 20 }}
              />
            ),
            children: deletedPages.map((e) => ({
              id: e.id,
              label: e.label,
              deleted: e.deleted,
              showOptions: true,
            })),
          },
        ]
      : []
  }, [deletedPages])

  return trashData
}
