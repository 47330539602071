import {
  BlockSchema,
  InlineContentSchema,
  PartialBlock,
  StyleSchema,
} from '@blocknote/core'
import { useQueryClient } from '@tanstack/react-query'
import { useApi } from '../../providers/ApiProvider'
import { promiseToaster, useToast } from '../../providers/Toast'

interface UpdatePage {
  id: string
  label: string
  blocks: PartialBlock<
    NoInfer<BlockSchema>,
    NoInfer<InlineContentSchema>,
    NoInfer<StyleSchema>
  >[]
  parent_id?: string
}

export interface Page {
  label: string
  blocks: PartialBlock<
    NoInfer<BlockSchema>,
    NoInfer<InlineContentSchema>,
    NoInfer<StyleSchema>
  >[]
  parent_id?: string
  created_by: string
  created_at: string
  updated_by: string
  updated_at: string
  organization: string
  children: Page[]
  deleted: boolean
  id: string
}

const API_BASE = '/api/pages/'

const usePagesApi = () => {
  const api = useApi()
  const { showToast } = useToast()

  const queryClient = useQueryClient()

  const getAllPages = async (
    deleted: boolean,
    skip: number = 0,
    limit: number = 100
  ) => {
    const data = await api.get<Page[]>(API_BASE, {
      params: {
        deleted,
        skip,
        limit,
      },
    })
    return data.data
  }

  const getPageById = async (id: string) => {
    const data = await api.get<Page>(API_BASE + id)
    return data.data
  }

  const createPage = promiseToaster(
    // Don't remove show param it will be fetch from toaster promise to display the toaster (always should be last param and pass from func call)
    async (parent_id?: string, show?: boolean) => {
      const data = await api.post<Page>(API_BASE, {
        label: 'Unititled',
        blocks: [
          {
            type: 'heading',
            content: 'Untitled',
          },
        ],
        parent_id,
      })

      queryClient.refetchQueries({
        queryKey: ['pages'],
      })

      return data.data
    },
    '',
    'Unable to create new page',
    showToast
  )

  const savePage = promiseToaster(
    // Don't remove show param it will be fetch from toaster promise to display the toaster (always should be last param and pass from func call)
    async ({ id, ...body }: UpdatePage, show: boolean) => {
      const content = body.blocks.find((e) => e.type === 'heading')?.content
      const label =
        typeof content === 'string'
          ? content
          : Array.isArray(content)
            ? (content[0] as any)?.text || body.label
            : body.label
      const data = await api.put(API_BASE + id, {
        ...body,
        label,
      })
      queryClient.refetchQueries({
        queryKey: ['page-data', id],
      })
      queryClient.refetchQueries({
        queryKey: ['pages'],
      })
      return data.data
    },
    'Page saved',
    'Unable to save page',
    showToast
  )

  const deletePage = promiseToaster(
    // Don't remove show param it will be fetch from toaster promise to display the toaster (always should be last param and pass from func call)
    async (id: string, perm: boolean, show: boolean) => {
      const data = await api.delete(API_BASE + id, {
        params: {
          perm,
        },
      })
      queryClient.refetchQueries({
        queryKey: ['pages'],
      })
      queryClient.refetchQueries({
        queryKey: ['pages-trash'],
      })

      return data.data
    },
    'Page has been deleted',
    'Unable to delete page',
    showToast
  )

  const restorePage = promiseToaster(
    // Don't remove show param it will be fetch from toaster promise to display the toaster (always should be last param and pass from func call)
    async (id: string, show: boolean) => {
      const data = await api.put(`${API_BASE}${id}/restore`)
      queryClient.refetchQueries({
        queryKey: ['pages'],
      })
      queryClient.refetchQueries({
        queryKey: ['pages-trash'],
      })

      return data.data
    },
    'Page has been restored',
    'Unable to restore page',
    showToast
  )

  return {
    getAllPages,
    createPage,
    getPageById,
    savePage,
    deletePage,
    restorePage,
  }
}

export default usePagesApi
