import { Protect, useOrganization } from '@clerk/clerk-react'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { RichTreeView, useTreeViewApiRef } from '@mui/x-tree-view'
import { useQueryClient } from '@tanstack/react-query'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import useWorkflowApi from '../Workflow/api'
import usePagesApi from './api'
import CustomItem from './TreeItem'
import { useTrashData, useTreeData, useWorkflowData } from './useData'

const PageTree: FC = () => {
  const { organization } = useOrganization()
  const { createPage } = usePagesApi()
  const { workflow } = useWorkflowApi()
  const treeRef = useTreeViewApiRef()
  const trashRef = useTreeViewApiRef()
  const canvasRef = useTreeViewApiRef()

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)

  const treeData = useTreeData()
  const workflowData = useWorkflowData()
  const trashData = useTrashData()

  const onSave = async (name: string) => {
    const data = await workflow.create({ name: name })
    queryClient.refetchQueries({
      queryKey: ['workflow', organization?.id],
    })
    navigate(`/auto/${data.id}`)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ mt: 3 }}>
      <RichTreeView
        apiRef={treeRef}
        sx={({ palette }) => ({
          '& .MuiTreeItem-content.Mui-selected': {
            background: palette.mode === 'light' ? '#fff' : undefined,
          },
        })}
        items={treeData || []}
        slots={{
          item: CustomItem,
        }}
        expansionTrigger={'iconContainer'}
        onItemClick={(_, id) => {
          const item = treeRef.current?.getItem(id)
          if (item.url) {
            navigate(item.url)
          }
        }}
      />
      <Divider />
      <Protect permission="org:feature:write_pages">
        <Button
          endIcon={<AddIcon />}
          sx={{
            textTransform: 'none',
            color: 'text.secondary ',
            width: '100%',
            justifyContent: 'space-between',
            px: 1,
          }}
          onClick={() => {
            createPage().then((e) => {
              navigate('/pages/' + e.id)
            })
          }}
        >
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            New page
          </Typography>
        </Button>
      </Protect>
      <Divider />

      <RichTreeView
        apiRef={canvasRef}
        sx={({ palette }) => ({
          '& .MuiTreeItem-content.Mui-selected': {
            background: palette.mode === 'light' ? '#fff' : undefined,
          },
        })}
        items={workflowData || []}
        slots={{
          item: CustomItem,
        }}
        expansionTrigger={'iconContainer'}
        onItemClick={(_, id) => {
          const item = canvasRef.current?.getItem(id)
          if (item?.url) {
            navigate(item.url)
          }
        }}
      />

      <Divider />
      {/* <Protect permission="org:feature:write_pages"> */}
      <Button
        endIcon={<AddIcon />}
        sx={{
          textTransform: 'none',
          color: 'text.secondary ',
          width: '100%',
          justifyContent: 'space-between',
          px: 1,
        }}
        onClick={handleClickOpen}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          New canvas
        </Typography>
      </Button>
      {/* </Protect> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries((formData as any).entries())
            const name = formJson.name
            onSave(name)
            handleClose()
          },
        }}
      >
        <DialogTitle>Create Canvas</DialogTitle>
        <DialogContent>
          <TextField
            autoComplete="off"
            autoFocus
            required
            margin="dense"
            id="canvas-name"
            name="name"
            label="Name"
            size="small"
            fullWidth
            variant="standard"
            helperText="Enter the name of canvas"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </Dialog>
      <Divider />

      <RichTreeView
        apiRef={trashRef}
        sx={({ palette }) => ({
          '& .MuiTreeItem-content.Mui-selected': {
            background: palette.mode === 'light' ? '#fff' : undefined,
          },
        })}
        items={trashData || []}
        slots={{
          item: CustomItem,
        }}
        expansionTrigger={'iconContainer'}
        onItemClick={(_, id) => {
          const item = trashRef.current?.getItem(id)
          if (item?.url) {
            navigate(item.url)
          }
        }}
      />
    </Box>
  )
}

export default PageTree
