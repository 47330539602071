import Box from '@mui/material/Box'
import { FC } from 'react'
import FloatingWidget from '../common/FloatingWidget'
import BottomNav from '../Floating/BottomNav'
import Logo from '../Floating/Logo'
import OrganizationSwitcher from '../Floating/OrganizationSelector'
import User from '../Floating/User'

const FloatingLayout: FC = () => {
  return (
    <>
      <FloatingWidget dockPosition="top-left">
        <Box display={'flex'} alignItems={'center'}>
          <Logo />
          <OrganizationSwitcher />
        </Box>
      </FloatingWidget>
      <FloatingWidget dockPosition="top-right">
        <User />
      </FloatingWidget>
      <FloatingWidget dockPosition="bottom-center">
        <BottomNav />
      </FloatingWidget>
    </>
  )
}

export default FloatingLayout
