import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { dark, light } from '../theme'

interface ITheme {
  toggleTheme: () => void
}

const defaultValue: ITheme = {
  toggleTheme: () => {},
}

const ThemeToggleContext = createContext(defaultValue)
const localThemeKey = 'hc-theme'

export const useThemeToggle = () => useContext(ThemeToggleContext).toggleTheme

const ThemeToggleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light')

  useEffect(() => {
    const savedMode = localStorage.getItem(localThemeKey)
    if (savedMode) {
      setMode(savedMode as any)
    }
  }, [])

  const theme = React.useMemo(() => (mode === 'dark' ? dark : light), [mode])

  const toggleTheme = useCallback(() => {
    setMode((currentMode) => {
      const newMode = currentMode === 'light' ? 'dark' : 'light'
      localStorage.setItem(localThemeKey, newMode)
      return newMode
    })
  }, [])

  useEffect(() => {
    //@ts-ignore
    window.toggleTheme = toggleTheme
  }, [toggleTheme])

  return (
    <ThemeProvider theme={theme}>
      <ThemeToggleContext.Provider
        value={{
          toggleTheme: toggleTheme,
        }}
      >
        <CssBaseline />
        {children}
      </ThemeToggleContext.Provider>
    </ThemeProvider>
  )
}

export default ThemeToggleProvider
