import { Box, Typography, useTheme } from '@mui/material'
import * as colors from '@mui/material/colors'
import { Handle, NodeProps, Position } from '@xyflow/react'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'
import HeroIcon from '../../HeroIcon'
import {
  FileNodeData,
  GroupNodeData,
  LinkNodeData,
  TextNodeData,
} from '../typing'

export const TextNode: FC<NodeProps<TextNodeData>> = ({ data }) => {
  // @ts-ignore
  const color = colors[data.color] || colors.grey
  const navigate = useNavigate()
  const theme = useTheme()
  const isDark = useMemo(() => theme.palette.mode === 'dark', [theme])
  return (
    <Box
      sx={{
        p: 2,
        border: `2px solid ${color[700]}`,
        height: '100%',
        borderRadius: '12px',
        background: color[50],
      }}
    >
      <Handle
        style={{ background: 'transparent', border: 'none' }}
        type="source"
        position={Position.Top}
        id={data.id + '-source-top'}
      />
      <Handle
        style={{ background: 'transparent', border: 'none' }}
        type="target"
        position={Position.Top}
        id={data.id + '-target-top'}
      />
      <Handle
        style={{ background: 'transparent', border: 'none' }}
        type="source"
        position={Position.Left}
        id={data.id + '-source-left'}
      />
      <Handle
        style={{ background: 'transparent', border: 'none' }}
        type="target"
        position={Position.Left}
        id={data.id + '-target-left'}
      />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bold', color: 'grey.800' }}
          >
            {data.label}
          </Typography>
          <HeroIcon
            iconName={data.icon as any}
            style={{
              height: '24px',
              stroke: color[isDark ? 50 : 800],
              background: color[isDark ? 800 : 100],
              borderRadius: '50%',
              padding: '4px',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', color: 'grey.800' }}
            >
              {data.description}
            </Typography>
            <Typography variant="subtitle3" sx={{ color: 'grey.600' }}>
              {data.caption}
            </Typography>
          </Box>
          {data.url && (
            <Box display={'flex'} alignItems={'flex-end'}>
              <HeroIcon
                iconName="ArrowUpRightIcon"
                height={'22px'}
                stroke={color[50]}
                style={{
                  cursor: 'pointer',
                  background: color[700],
                  borderRadius: '50%',
                  padding: '4px',
                }}
                onClick={() => navigate(data.url)}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Handle
        style={{ background: 'transparent', border: 'none' }}
        type="source"
        position={Position.Right}
        id={data.id + '-source-right'}
      />
      <Handle
        style={{ background: 'transparent', border: 'none' }}
        type="target"
        position={Position.Right}
        id={data.id + '-target-right'}
      />
      <Handle
        style={{ background: 'transparent', border: 'none' }}
        type="source"
        position={Position.Bottom}
        id={data.id + '-source-bottom'}
      />
      <Handle
        style={{ background: 'transparent', border: 'none' }}
        type="target"
        position={Position.Bottom}
        id={data.id + '-target-bottom'}
      />
    </Box>
  )
}

export const FileNode: FC<NodeProps<FileNodeData>> = ({ data }) => (
  <div className="p-4 bg-white rounded-lg shadow border">
    <Handle type="target" position={Position.Top} />
    <Handle type="target" position={Position.Left} />
    <div className="flex flex-col gap-1">
      <div className="text-sm font-medium">File: {data.file}</div>
      {data.subpath && (
        <div className="text-xs text-gray-500">Subpath: {data.subpath}</div>
      )}
    </div>
    <Handle type="source" position={Position.Right} />
    <Handle type="source" position={Position.Bottom} />
  </div>
)

export const LinkNode: FC<NodeProps<LinkNodeData>> = ({ data }) => (
  <div className="p-4 bg-white rounded-lg shadow border">
    <Handle type="target" position={Position.Top} />
    <Handle type="target" position={Position.Left} />
    <a
      href={data.url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-sm text-blue-500 hover:underline"
    >
      {data.url}
    </a>
    <Handle type="source" position={Position.Right} />
    <Handle type="source" position={Position.Bottom} />
  </div>
)

export const GroupNode: FC<NodeProps<GroupNodeData>> = ({ data }) => (
  <div
    className={`p-4 rounded-lg border-2 border-dashed ${
      data.background ? 'bg-cover bg-center' : 'bg-gray-50'
    }`}
    style={{
      backgroundImage: data.background ? `url(${data.background})` : undefined,
      backgroundSize:
        data.backgroundStyle === 'cover'
          ? 'cover'
          : data.backgroundStyle === 'ratio'
            ? 'contain'
            : data.backgroundStyle === 'repeat'
              ? 'repeat'
              : undefined,
    }}
  >
    <Handle type="target" position={Position.Top} />
    <Handle type="target" position={Position.Left} />
    {data.label && (
      <div className="text-sm font-medium mb-2 bg-white/80 px-2 py-1 rounded">
        {data.label}
      </div>
    )}
    <Handle type="source" position={Position.Right} />
    <Handle type="source" position={Position.Bottom} />
  </div>
)
