import { useOrganization } from '@clerk/clerk-react'
import { useMemo } from 'react'

const useOrg = () => {
  const { organization } = useOrganization()
  const isHelpcareHq = useMemo(
    () => organization?.slug === 'helpcare',
    [organization?.slug]
  )

  return {
    isHelpcareHq,
    slug: organization?.slug,
    name: organization?.name,
  }
}

export default useOrg
