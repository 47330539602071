import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

const David = () => {
  return (
    <Paper variant="elevation" elevation={4} sx={{ p: 2, width: 400 }}>
      <Typography variant="h6" sx={{ pb: 1 }}>
        Scheduling Screen Recording
      </Typography>
      <Box
        component="video"
        src={
          'https://storage.googleapis.com/helpcare_website_audio_files/jessica-short-version.mov'
        }
        controls
        onError={(e: React.SyntheticEvent<HTMLVideoElement>) => {
          const target = e.target as HTMLVideoElement
          console.error('Video error:', target.error)
        }}
        sx={{
          width: '100%',
          borderRadius: 1,
        }}
      />
    </Paper>
  )
}

export default David
