import {
  createContext,
  CSSProperties,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

type RootTypes = '' | 'duties'

interface Config {
  canvasHeight: number
  paper: {
    sx: CSSProperties
  }
}

// Use to set page level configs for router modals, ex. drawer height etc
const config: Record<RootTypes, Config> = {
  duties: {
    canvasHeight: 264,
    paper: {
      sx: {
        height: 'calc(100vh - 264px)',
      },
    },
  },
  '': {
    canvasHeight: 64,
    paper: {
      sx: {
        height: 'calc(100vh - 64px)',
      },
    },
  },
}

interface IBackgroundModal {
  root: RootTypes
  setRoot: Dispatch<SetStateAction<RootTypes>>
  config: Config
}

const defaultValue: IBackgroundModal = {
  root: '',
  setRoot: () => {},
  config: config[''],
}

const BackgroundModalContext = createContext(defaultValue)

const BackgroundModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [root, setRoot] = useState<RootTypes>('')

  const configValue = useMemo(() => config[root], [root])

  return (
    <BackgroundModalContext.Provider
      value={{ root, setRoot, config: configValue }}
    >
      {children}
    </BackgroundModalContext.Provider>
  )
}

export const useBackgroundModal = () => useContext(BackgroundModalContext)

export default BackgroundModalProvider
