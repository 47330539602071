import { BlockNoteView } from '@blocknote/mantine'
import { useCreateBlockNote } from '@blocknote/react'
import { Protect } from '@clerk/clerk-react'
import Edit from '@mui/icons-material/Edit'
import Save from '@mui/icons-material/Save'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { FC, useMemo, useState } from 'react'
import useAutoSave from '../../hooks/useAutoSave'
import useHelpCenterApi, { RootResponse } from './api'

const HelpCenterPage: FC<{
  data: RootResponse
}> = ({ data }) => {
  const { palette } = useTheme()
  const { saveHelpCenter } = useHelpCenterApi()
  const [isEdit, setIsEdit] = useState(false)

  const formattedBlocks = useMemo(() => {
    return data ? data.blocks : [{ type: 'heading', content: 'Help Center' }]
  }, [data])

  const editor = useCreateBlockNote({
    //@ts-ignore
    initialContent: formattedBlocks || [
      { type: 'heading', content: 'Help Center' },
    ],
  })

  const save = async (isAutoSave: boolean) => {
    console.log('Auto Save', isAutoSave)
    data.blocks = editor.document as any
    await saveHelpCenter(data.blocks)
    if (!isAutoSave && isEdit) {
      setIsEdit(false)
    }
  }
  const [setBlocks] = useAutoSave(isEdit, editor, save)

  // Renders the editor instance using a React component.
  return (
    <>
      <Protect role="org:global_admin">
        <Box
          sx={({ palette }) => ({
            minHeight: '54px',
            height: '54px',
            gap: 2,
            justifyContent: 'space-between',
            borderBottom: `1px solid ${palette.divider}`,
          })}
        >
          {!isEdit ? (
            <Button
              color="primary"
              onClick={() => setIsEdit(true)}
              endIcon={<Edit />}
              size="small"
            >
              Edit
            </Button>
          ) : (
            <>
              <Stack direction="row" spacing={2}>
                <Button
                  color="primary"
                  onClick={() => setIsEdit(false)}
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => save(false)}
                  endIcon={<Save />}
                  size="small"
                >
                  Save
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Protect>

      <BlockNoteView
        editor={editor}
        editable={isEdit}
        theme={palette.mode}
        onChange={() => setBlocks(editor.document)}
      />
    </>
  )
}

export default HelpCenterPage
