import { Edit, Save } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useToast } from '../../providers/Toast'
import LiquidEditor from '../LiquidEditor'
import Loader from '../Loader'
import usePromptScriptApi from './api'

const PromptDetail = () => {
  const [isEdit, setIsEdit] = useState(false)
  const { key, id } = useParams<{ key: string; id: string }>()

  const { getPromptScriptsDetail, updatePrompt } = usePromptScriptApi()
  const client = useQueryClient()
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['prompt', key, id],
    queryFn: () => getPromptScriptsDetail(key || '', id || ''),
    enabled: !!key && !!id,
  })
  const { showToast } = useToast()
  const [text, setText] = useState('')

  const { mutate } = useMutation({
    mutationKey: ['prompt-edit', key, id],
    mutationFn: () => updatePrompt(key || '', id || '', text),
    onSuccess: () => {
      setIsEdit(false)
      showToast('Prompt updated', 'success')
    },
    onError: () => {
      showToast('Error updating prompts', 'error')
    },
  })

  const save = () => {
    if (data?.prompt !== text) {
      mutate()
      client.invalidateQueries({
        queryKey: ['prompt', key, id],
      })
      refetch()
    }
  }

  useEffect(() => {
    if (data) {
      setText(data.prompt)
    }
  }, [data])

  if (isLoading) return <Loader />

  if (!data) return <></>
  return (
    <Box sx={{ p: 2 }}>
      <Box display={'flex'} alignItems={'center'} sx={{ mb: 2 }}>
        <Typography variant="h6" component={'h2'}>
          {data.prompt_name}
        </Typography>
        {isEdit ? (
          <IconButton
            onClick={() => {
              save()
            }}
          >
            <Save color="primary" />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              setIsEdit(true)
            }}
          >
            <Edit color="primary" />
          </IconButton>
        )}
      </Box>
      {isEdit ? (
        <LiquidEditor text={text} setText={setText} />
      ) : (
        <Typography variant="body3">{text}</Typography>
      )}
    </Box>
  )
}

export default PromptDetail
