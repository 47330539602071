import { useCreateBlockNote } from '@blocknote/react'
import { Protect, useOrganization } from '@clerk/clerk-react'
import Edit from '@mui/icons-material/Edit'
import Save from '@mui/icons-material/Save'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import usePromptApi from '../FormatPageDialog/api'
import useMeetingsApi from './api'

interface MeetingToolbarProps {
  save: () => void
  editState: [boolean, Dispatch<SetStateAction<boolean>>]
  meetingId: string
}

const MeetingToolbar: FC<MeetingToolbarProps> = ({
  save,
  editState,
  meetingId,
}) => {
  const [isEdit, setIsEdit] = editState

  const [selection, setSelection] = useState<string>('')
  const { getPrompts } = usePromptApi()
  const { formatPage } = useMeetingsApi()
  const { organization } = useOrganization()

  const { data } = useQuery({
    queryKey: ['availablePrompts', organization?.id],
    queryFn: getPrompts,
  })
  const editor = useCreateBlockNote()
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const template = data?.find((e) => e.id === selection)
      const templateBlocks = template?.blocks || []
      let idx = templateBlocks.findIndex(
        (p) => p.type === 'heading' && p.props.level === 1
      )
      if (idx >= 0) {
        templateBlocks.splice(idx, 1)
      }
      const md = await editor.blocksToMarkdownLossy(templateBlocks)
      return formatPage(meetingId, md, template?.id || '')
    },
    onSuccess: () => {},
  })

  const handleChange = (event: SelectChangeEvent) => {
    setSelection(event.target.value as string)
  }

  return (
    <Protect
      condition={(has: any) =>
        has({ permission: 'org:feature:write_meeting_notes' })
      }
    >
      <Toolbar
        sx={({ palette }) => ({
          minHeight: '54px',
          height: '54px',
          gap: 2,
          justifyContent: 'space-between',
          borderBottom: `1px solid ${palette.divider}`,
        })}
      >
        {(data || [])?.length > 0 && (
          <Box display={'flex'} flexDirection={'row'} gap={2}>
            <FormControl
              sx={{ width: 250, height: 40, '& legend': { width: '58px' } }}
              color="secondary"
              size="small"
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: '16px' }}
              >
                Template
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selection}
                label="Template"
                onChange={handleChange}
                sx={{ maxHeight: 40 }}
              >
                <MenuItem value={''}>None</MenuItem>
                {data?.map((e) => (
                  <MenuItem value={e.id} key={e.id}>
                    {e.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selection && (
              <Button
                color="primary"
                size="small"
                endIcon={
                  isPending && (
                    <CircularProgress size={14} sx={{ color: '#fff' }} />
                  )
                }
                disabled={!selection}
                onClick={() => {
                  mutate()
                }}
              >
                Format
              </Button>
            )}
          </Box>
        )}
        {!isEdit ? (
          <Button
            color="primary"
            onClick={() => setIsEdit(true)}
            endIcon={<Edit />}
            size="small"
          >
            Edit
          </Button>
        ) : (
          <>
            <Stack direction="row" spacing={2}>
              <Button
                color="primary"
                onClick={() => setIsEdit(false)}
                size="small"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => save()}
                endIcon={<Save />}
                size="small"
              >
                Save
              </Button>
            </Stack>
          </>
        )}
      </Toolbar>
    </Protect>
  )
}

export default MeetingToolbar
