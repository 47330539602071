import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router'
import angelica from '../../../../images/angelica.png'

const AngelicaToolip = () => {
  const navigate = useNavigate()
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box component={'img'} src={angelica} alt={"David's profile picture"} />
        <Typography variant="subtitle3">
          Start outreach with Angelica
        </Typography>
      </Box>
      <Button
        onClick={() => navigate('/begin-outreach')}
        sx={(theme) => ({
          textTransform: 'none',
          background: alpha(
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.black,
            16
          ),
          color: alpha(
            theme.palette.mode === 'light'
              ? theme.palette.common.white
              : theme.palette.common.black,
            16
          ),
          borderRadius: '24px',
          mb: -3,
          width: '100%',
        })}
      >
        {' '}
        Get Started{' '}
      </Button>
    </Box>
  )
}

export default AngelicaToolip
