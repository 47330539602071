import { Protect } from '@clerk/clerk-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { FC } from 'react'
import { useParams } from 'react-router'
import Loader from '../../Loader'
import TableEditor from '../../TableEditor'
import useTablesApi from '../../TableEditor/api'
import AnimatedProgress from '../../common/ReportsProgress'
import Dashboards from '../Dashboards'
import useReportsApi, { ReportDetail } from './api'

const TableReport: FC<{ data: ReportDetail }> = ({ data }) => {
  const { fetchData } = useTablesApi()

  const tableData = useQuery({
    queryKey: ['table-data', data.score_view],
    queryFn: () =>
      fetchData(data.score_view || '', { items: [] }, [], {
        pageSize: 1,
        page: 0,
      }),
    enabled: Boolean(data.score_view),
  })

  return (
    <>
      <Box display={'flex'} alignItems={'center'} width={'100%'} gap={1}>
        <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
          {data.title}
        </Typography>
        <AnimatedProgress
          value={(tableData.data?.rows[0]?.completion_score || 0) * 100}
        />
      </Box>
      <TableEditor
        hideHeader
        tableName={data.table_name}
        readonly={!data.editable}
        readonlyFields={data.readonlyFields || []}
        onEditComplete={() => {
          setTimeout(() => {
            tableData.refetch()
          }, 100)
        }}
      />
    </>
  )
}

const Reports: FC = () => {
  const params = useParams()

  const { getReportById } = useReportsApi()

  const { data, isLoading } = useQuery({
    queryKey: ['report-data', params.id],
    queryFn: () => getReportById(params.id || ''),
  })

  if (isLoading) {
    return <Loader />
  }

  if (!data) {
    return <></>
  }

  if (data.source === 'dashboard') {
    return (
      <>
        <Protect
          condition={(has: any) =>
            has({ permission: 'org:feature:read_data_view' })
          }
          fallback={
            <Typography variant="h2">
              You are not allowed to access this page
            </Typography>
          }
        >
          <Dashboards></Dashboards>
        </Protect>
      </>
    )
  }

  return <TableReport data={data} />
}

export default Reports
