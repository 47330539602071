import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup'
import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router'

const BottomNav: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const value = location.pathname.split('/')[1]

  return (
    <Box>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={(_, value) => {
          if (value) {
            navigate(`/${value}`)
          }
        }}
        sx={{
          [`& .${toggleButtonGroupClasses.grouped}`]: {
            border: 0,
          },
          [`& .${toggleButtonGroupClasses.selected}`]: {
            borderRadius: 1,
          },
        }}
        aria-label="app navigation"
      >
        <ToggleButton value="actions" aria-label="actions">
          <CheckBoxOutlinedIcon sx={{ mr: 0.5 }} /> Actions
        </ToggleButton>
        <ToggleButton value="my-data" aria-label="italic">
          <WidgetsOutlinedIcon sx={{ mr: 0.5 }} /> Data
        </ToggleButton>
        <ToggleButton value="my-meetings" aria-label="underlined">
          <DescriptionOutlinedIcon sx={{ mr: 0.5 }} /> Docs
        </ToggleButton>
        <ToggleButton value="call-logs" aria-label="color">
          <CallOutlinedIcon sx={{ mr: 0.5 }} /> Calls
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default BottomNav
