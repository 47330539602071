import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import {
  Location,
  Params,
  Link as RouterLink,
  useLocation,
  useParams,
} from 'react-router-dom'
import useOrg from '../../hooks/useOrg'
import useDutiesApi, { DutyDetail } from '../Duties/api'
import useDynamicViewApi, { DynamicView } from '../DynamicView/api'
import useMeetingsApi from '../Meetings/api'
import { MeetingsResponse } from '../Meetings/typing'
import useReportsApi, { ReportDetail } from '../MyData/Reports/api'
import usePagesApi, { Page } from '../PageTree/api'
import usePromptScriptApi, { PromptScript } from '../PromptScript/api'
import useWorkflowApi, { WorkflowDetails } from '../Workflow/api'

/* The `breadcrumbNameMap` constant is an object that maps specific static route segments to their
corresponding display names in the breadcrumbs. Each key in the object represents a route segment,
and the corresponding value is the display name that should be shown in the breadcrumbs for that
segment. */

const breadcrumbNameMap: { [key: string]: string } = {
  meetings: 'Meetings',
  'my-meetings': 'My Meetings',
  'my-data': 'My Data',
  reports: 'Reports',
  'help-center': 'Help Center',
  pages: 'Page',
  workflows: 'Workflow',
  actions: 'My Actions',
  'call-logs': 'All Calls',
  'begin-outreach': 'Begin Outreach',
  detail: 'Details',
  individual: 'Journey',
  view: 'View',
  duties: 'Duties',
  prompt: 'Prompt',
  voicemail: 'Voicemail',
  script: 'Script',
  history: 'History',
}

const disabledRoutes = new Set(['prompt', 'voicemail', 'script'])

const resolveDynamicPathSegment = (
  segment: string,
  location: Location,
  params: Readonly<Params<string>>,
  data: {
    reports?: ReportDetail
    pageData?: Page
    meetingData?: MeetingsResponse
    workflowData?: WorkflowDetails
    viewData?: DynamicView
    promptData?: PromptScript
    dutyDetail?: DutyDetail
  }
) => {
  // First check if it's a known static route
  if (breadcrumbNameMap[segment]) {
    return breadcrumbNameMap[segment]
  }

  // Check if this segment matches any of our params
  if (params.id === segment) {
    if (location.pathname.includes('my-data') && data.reports) {
      return data.reports.title || segment
    }
    if (location.pathname.includes('pages') && data.pageData) {
      return data.pageData.label || segment
    }
    if (location.pathname.includes('workflows') && data.workflowData) {
      return data.workflowData.name || segment
    }
    if (location.pathname.includes('view') && data.viewData) {
      return data.viewData.view_name || segment
    }
    if (data.meetingData?.meta?.label) {
      return data.meetingData.meta.label
    }
  }

  if (params.dutyId === segment && data.dutyDetail) {
    return data.dutyDetail.Name || segment
  }

  if (params.id === segment && data.promptData) {
    return data.promptData.prompt_name || segment
  }

  // Return the segment as is if no resolution is found
  return slugResolution(segment, location)
}

const slugResolution = (slug: string, location: Location) => {
  if (location.pathname.includes('/begin-outreach')) {
    return slug?.replaceAll('-', ' ')
  }
  return slug
}

const Breadcrumbs = () => {
  const location = useLocation()
  const params = useParams()
  const { slug, name } = useOrg()

  // API hooks
  const { getMeetingById } = useMeetingsApi()
  const { getReportById } = useReportsApi()
  const { getPageById } = usePagesApi()
  const { workflow } = useWorkflowApi()
  const { getView } = useDynamicViewApi()
  const { getDutyDetails } = useDutiesApi()
  const { getPromptScriptsDetail } = usePromptScriptApi()

  // Data fetching
  const { data: meetingData } = useQuery({
    queryKey: ['meeting-data', params.id],
    queryFn: () => getMeetingById(params.id || ''),
    enabled:
      (location.pathname.includes('meetings') ||
        location.pathname.includes('my-meetings')) &&
      Boolean(params.id),
  })

  const { data: reports } = useQuery({
    queryKey: ['report-data', params.id],
    queryFn: () => getReportById(params.id || ''),
    enabled: location.pathname.includes('my-data') && Boolean(params.id),
  })

  const { data: pageData } = useQuery({
    queryKey: ['page-data', params.id],
    queryFn: () => getPageById(params.id || ''),
    enabled: location.pathname.includes('pages') && Boolean(params.id),
  })

  const { data: workflowData } = useQuery({
    queryKey: ['workflow', params.id],
    queryFn: () => workflow.get(params.id || ''),
    enabled: location.pathname.includes('workflows') && Boolean(params.id),
  })

  const { data: viewData } = useQuery({
    queryKey: ['view', params.id],
    queryFn: () => getView(params.id || ''),
    enabled: location.pathname.includes('view') && Boolean(params.id),
  })

  const { data: promptData } = useQuery({
    queryKey: ['prompt', params.key, params.id],
    queryFn: () => getPromptScriptsDetail(params.key || '', params.id || ''),
    enabled:
      Boolean(params.dutyId) && Boolean(params.key) && Boolean(params.id),
  })

  const { data: dutyDetail } = useQuery({
    queryKey: ['duty', slug, params.dutyId],
    queryFn: () => getDutyDetails(params.dutyId || ''),
    enabled: Boolean(params.dutyId),
  })

  // Get all path segments
  const pathSegments = useMemo(
    () => location.pathname.split('/').filter((segment) => segment.length > 0),
    [location.pathname]
  )

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{ display: 'flex' }}
      separator={
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          /
        </Typography>
      }
    >
      <MuiLink
        component={RouterLink}
        color="inherit"
        to="/"
        sx={{ textDecoration: 'none' }}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {name || 'Helpcare'}
        </Typography>
      </MuiLink>

      {pathSegments.map((segment, index) => {
        const last = index === pathSegments.length - 1
        const link = '/' + pathSegments.slice(0, index + 1).join('/')

        const resolvedName = resolveDynamicPathSegment(
          segment,
          location,
          params,
          {
            reports,
            pageData,
            meetingData,
            workflowData,
            viewData,
            promptData,
            dutyDetail,
          }
        )

        return last ? (
          <Typography
            variant="subtitle2"
            key={link}
            sx={{ color: 'text.secondary' }}
          >
            {resolvedName}
          </Typography>
        ) : (
          <MuiLink
            component={RouterLink}
            to={link}
            key={link}
            color="inherit"
            sx={{
              textDecoration: 'none',
              pointerEvents: disabledRoutes.has(segment) ? 'none' : 'auto',
            }}
          >
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {resolvedName}
            </Typography>
          </MuiLink>
        )
      })}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
