import * as MuiIcons from '@mui/icons-material'
import { SvgIconComponent } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'

export interface IconOption {
  name: string
  Icon: SvgIconComponent
}

export interface IconAutocompleteProps {
  selectedIcon: string
  onChange: (icon: IconOption | null) => void
  className?: string
}

const IconAutocomplete: React.FC<IconAutocompleteProps> = ({
  selectedIcon,
  onChange,
}) => {
  const [text, setText] = useState(selectedIcon)
  // Convert the imported icons object into an array of icon names
  const iconList: IconOption[] = Object.keys(MuiIcons)
    .filter((key): key is keyof typeof MuiIcons => true)
    .map((iconName) => ({
      name: iconName,
      Icon: MuiIcons[iconName] as SvgIconComponent,
    }))

  const handleIconChange = (
    _event: React.SyntheticEvent,
    newValue: IconOption | null
  ): void => {
    setText(newValue?.name || '')
    onChange(newValue)
  }

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement> & { key: any },
    option: IconOption
  ): React.ReactNode => {
    const { key, ...rest } = props
    return (
      <Box
        key={key}
        component="li"
        sx={({ typography }) => ({
          '& > svg': {
            mr: 2,
            fontSize: 16,
          },
          ...typography.subtitle3,
        })}
        {...rest}
      >
        <option.Icon />
        {option.name}
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 500, margin: '20px auto' }}>
      <Autocomplete<IconOption, false, false, false>
        options={iconList
          .filter((e) => e.name.toLowerCase().includes(text.toLowerCase()))
          .slice(0, 100)}
        getOptionLabel={(option: IconOption): string => option.name}
        renderOption={renderOption}
        renderInput={(params): React.ReactNode => (
          <TextField
            {...params}
            label="Search Icons"
            variant="standard"
            size="small"
            fullWidth
          />
        )}
        value={iconList.find((e) => e.name === text) || null}
        onChange={handleIconChange}
        onInput={(e) => {
          //@ts-ignore
          setText(e.target.value)
        }}
        isOptionEqualToValue={(
          option: IconOption,
          value: IconOption
        ): boolean => option.name === value.name}
      />
    </Box>
  )
}

export default IconAutocomplete
