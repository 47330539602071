import { useMediaQuery, useTheme } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import AppLogo from '../icons/Logo'

const Logo: FC = () => {
  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('md'))
  const navigate = useNavigate()
  return (
    <AppLogo
      onClick={() => navigate('/')}
      sx={{
        cursor: 'pointer',
        height: isMobile ? 20 : 30,
        width: isMobile ? 20 : 30,
      }}
    />
  )
}

export default Logo
