import Close from '@mui/icons-material/Close'
import Help from '@mui/icons-material/Help'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import Drawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import { FC, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useBackgroundModal } from '../../../providers/BackgroundModal'
import Breadcrumbs from '../../Breadcrumb'
import PageTree from '../../PageTree'

const drawerWidth = 200
const RouterModal: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { config, root } = useBackgroundModal()

  const showDrawer = useMemo(
    () => location.pathname.length > 1,
    [location.pathname]
  )

  const onClose = () => {
    /* Every new background canvas you define must be accounted for here, to set height and etc for pages */
    if (root === 'duties') {
      navigate(-1)
    } else {
      navigate('/')
    }
  }

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={showDrawer}
      onOpen={() => {}}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Helps with animation when opening and closing
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
      sx={{
        zIndex: 0,
        '& .MuiDrawer-paper': {
          height: `calc(100vh - ${config.canvasHeight}px)`,
          boxSizing: 'border-box',
          borderTopLeftRadius: 8,
          zIndex: 0,
          borderTopRightRadius: 8,
          transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1)',
          ...config.paper.sx,
        },
      }}
    >
      <Box sx={{ pt: 1 }}>
        <Box
          sx={({ palette }) => ({
            borderBottom: `1px solid ${palette.divider}`,
            pb: 1,
            px: 2,
          })}
        >
          <Breadcrumbs />
          <Close
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'text.secondary',
              height: 20,
              width: 20,
            }}
            onClick={onClose}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={({ palette }) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: `calc(100vh - ${config.canvasHeight + 34}px)`, // leave 34px for modal header which holds breadcrumbs etc.
              width: drawerWidth,
              borderRight: `1px solid ${palette.divider}`,
              background: palette.mode === 'light' ? '#F1F4F8' : undefined,
            })}
          >
            <PageTree />
            <Box
              sx={(theme) => ({
                px: 2,
                py: 1,
                '&: hover': {
                  background: alpha(theme.palette.text.primary, 0.08),
                },
              })}
            >
              <MuiLink
                component={RouterLink}
                color="inherit"
                to="/help-center"
                sx={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <Help
                  fontSize="small"
                  sx={{ mr: 1, color: 'text.secondary' }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ color: 'text.secondary' }}
                >
                  Help Center
                </Typography>
              </MuiLink>
            </Box>
          </Box>
          <Box
            sx={{
              p: 1,
              width: '100%',
              height: `calc(100vh - ${config.canvasHeight + 34}px)`,
              overflowY: 'auto',
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default RouterModal
