import { blue as muiBlue, red as muiRed } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { CSSProperties } from 'react'
import typography from './baseTypography'
import breakpoints from './beakpoints'
import { blue, red } from './color'
import overrides from './components'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: CSSProperties
    subtitle3: CSSProperties
  }
  interface TypographyVariantsOptions {
    body3?: CSSProperties
    subtitle3?: CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
    subtitle3: true
    caption: false
  }
}

Object.assign(muiRed, red)
Object.assign(muiBlue, blue)

const light = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#DA05FF',
      light: muiBlue[50],
      dark: muiBlue[700],
    },
    text: {
      primary: '#14181b',
      secondary: '#57636c',
    },
  },
  typography: typography,
  breakpoints: breakpoints,
  components: overrides,
})

const dark = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#861F99',
      light: muiBlue[50],
      dark: muiBlue[700],
    },
    text: {
      primary: '#c9c9c9',
      secondary: '#95a1ac',
    },
  },
  typography: typography,
  breakpoints: breakpoints,
  components: overrides,
})

export { dark, light }
