import { Edge, Node } from '@xyflow/react'
import { useApi } from '../../providers/ApiProvider'
import { CanvasEdge, CanvasNode } from '../JSONCanvas/typing'
import { edgeMapper, nodeMapper } from '../JSONCanvas/util'

export interface Root {
  nodes: CanvasNode[]
  edges: CanvasEdge[]
}

const useHomeCanvasApi = () => {
  const api = useApi()

  const getCanvas = async (): Promise<{ nodes: Node[]; edges: Edge[] }> => {
    const data = await api.get<Root>(`/api/canvas/`)

    return {
      nodes: (data.data?.nodes || []).map(nodeMapper),
      edges: (data.data?.edges || []).map(edgeMapper),
    }
  }

  return {
    getCanvas,
  }
}

export default useHomeCanvasApi
