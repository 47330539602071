export const qualityStatisticsColumn = [
  {
    field: 'First Name',
    headerName: 'First Name : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Last Name',
    headerName: 'Last Name : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Gender',
    headerName: 'Gender : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Date Of Birth',
    headerName: 'Date Of Birth : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Address',
    headerName: 'Address : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Zipcode',
    headerName: 'Zipcode : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Phone',
    headerName: 'Phone : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Email Address',
    headerName: 'Email Address : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Race',
    headerName: 'Race : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Sexual Orientation',
    headerName: 'Sexual Orientation : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Ethnicity',
    headerName: 'Ethnicity : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Veteran',
    headerName: 'Veteran : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
  {
    field: 'Insurance',
    headerName: 'Insurance : Missing (%)',
    width: 150,
    renderCell: (params: any) => {
      return `${params.value} %`
    },
  },
]

export const caseManagementColumn = [
  {
    field: 'Universal Patient ID',
    width: 150,
  },
  {
    field: 'First Name',
    width: 150,
  },
  {
    field: 'Middle Name',
    width: 150,
  },
  {
    field: 'Last Name',
    width: 150,
  },
  {
    field: 'Address',
    width: 150,
  },
  {
    field: 'Zipcode',
    width: 150,
  },
  {
    field: 'Date Of Birth',
    width: 150,
  },
  {
    field: 'Gender',
    width: 150,
  },
  {
    field: 'Sexual Orientation',
    width: 150,
  },
  {
    field: 'Ethnicity',
    width: 150,
  },
  {
    field: 'Insurance',
    width: 150,
  },
  {
    field: 'Race',
    width: 150,
  },
  {
    field: 'Veteran',
    width: 150,
  },
  {
    field: 'Phone',
    width: 150,
  },
  {
    field: 'Clarity ID',
    width: 150,
  },
]

export const pantryColumn = [
  {
    field: 'Universal Patient ID',
    width: 150,
  },
  {
    field: 'First Name',
    width: 150,
  },
  {
    field: 'Middle Name',
    width: 150,
  },
  {
    field: 'Last Name',
    width: 150,
  },
  {
    field: 'Address',
    width: 150,
  },
  {
    field: 'Zipcode',
    width: 150,
  },
  {
    field: 'Date Of Birth',
    width: 150,
  },
  {
    field: 'Gender',
    width: 150,
  },
  {
    field: 'Sexual Orientation',
    width: 150,
  },
  {
    field: 'Ethnicity',
    width: 150,
  },
  {
    field: 'Insurance',
    width: 150,
  },
  {
    field: 'Race',
    width: 150,
  },
  {
    field: 'Veteran',
    width: 150,
  },
  {
    field: 'Phone',
    width: 150,
  },
  {
    field: 'Clarity ID',
    width: 150,
  },
]

export const ecwColumn = [
  {
    field: 'Universal Patient ID',
    width: 150,
  },
  {
    field: 'First Name',
    width: 150,
  },
  {
    field: 'Middle Name',
    width: 150,
  },
  {
    field: 'Last Name',
    width: 150,
  },
  {
    field: 'Address',
    width: 150,
  },
  {
    field: 'Zipcode',
    width: 150,
  },
  {
    field: 'Date Of Birth',
    width: 150,
  },
  {
    field: 'Gender',
    width: 150,
  },
  {
    field: 'Sexual Orientation',
    width: 150,
  },
  {
    field: 'Ethnicity',
    width: 150,
  },
  {
    field: 'Insurance',
    width: 150,
  },
  {
    field: 'Race',
    width: 150,
  },
  {
    field: 'Veteran',
    width: 150,
  },
  {
    field: 'Phone',
    width: 150,
  },
  {
    field: 'Relevant ID',
    width: 150,
  },
]

export const wpcColumn = [
  {
    field: 'Universal Patient ID',
    width: 150,
  },
  {
    field: 'First Name',
    width: 150,
  },
  {
    field: 'Middle Name',
    width: 150,
  },
  {
    field: 'Last Name',
    width: 150,
  },
  {
    field: 'Address',
    width: 150,
  },
  {
    field: 'Zipcode',
    width: 150,
  },
  {
    field: 'Date Of Birth',
    width: 150,
  },
  {
    field: 'Gender',
    width: 150,
  },
  {
    field: 'Sexual Orientation',
    width: 150,
  },
  {
    field: 'Ethnicity',
    width: 150,
  },
  {
    field: 'Insurance',
    width: 150,
  },
  {
    field: 'Race',
    width: 150,
  },
  {
    field: 'Veteran',
    width: 150,
  },
  {
    field: 'Phone',
    width: 150,
  },
]

export const safetyNetColumn = [
  {
    field: 'Universal Patient ID',
    width: 150,
  },
  {
    field: 'First Name',
    width: 150,
  },
  {
    field: 'Middle Name',
    width: 150,
  },
  {
    field: 'Last Name',
    width: 150,
  },
  {
    field: 'Address',
    width: 150,
  },
  {
    field: 'Zipcode',
    width: 150,
  },
  {
    field: 'Date Of Birth',
    width: 150,
  },
  {
    field: 'Gender',
    width: 150,
  },
  {
    field: 'Sexual Orientation',
    width: 150,
  },
  {
    field: 'Ethnicity',
    width: 150,
  },
  {
    field: 'Insurance',
    width: 150,
  },
  {
    field: 'Race',
    width: 150,
  },
  {
    field: 'Veteran',
    width: 150,
  },
  {
    field: 'Phone',
    width: 150,
  },
  {
    field: 'Email Address',
    width: 150,
  },
]

export const updColumn = [
  {
    field: 'Universal Patient ID',
    width: 150,
  },
  {
    field: 'First Name',
    width: 150,
  },
  {
    field: 'Middle Name',
    width: 150,
  },
  {
    field: 'Last Name',
    width: 150,
  },
  {
    field: 'Address',
    width: 150,
  },
  {
    field: 'Zipcode',
    width: 150,
  },
  {
    field: 'Date Of Birth',
    width: 150,
  },
  {
    field: 'Gender',
    width: 150,
  },
  {
    field: 'Sexual Orientation',
    width: 150,
  },
  {
    field: 'Ethnicity',
    width: 150,
  },
  {
    field: 'Insurance',
    width: 150,
  },
  {
    field: 'Race',
    width: 150,
  },
  {
    field: 'Veteran',
    width: 150,
  },
  {
    field: 'Phone',
    width: 150,
  },
  {
    field: 'Service: Succesfully Housed : Clarity',
    width: 150,
  },
  {
    field: 'Service: Shower : Clarity',
    width: 150,
  },
  {
    field: 'Service: SSI Referral : Clarity',
    width: 150,
  },
  {
    field: 'Service: Legal Aid Referral : Clarity',
    width: 150,
  },
  {
    field: 'Service: Ritter CM Bus Pass : Clarity',
    width: 150,
  },
  {
    field: 'Service: Clothing Closet : Clarity',
    width: 150,
  },
  {
    field: 'Service: Pantry : Clarity',
    width: 150,
  },
  {
    field: 'Service: Jonathan Place Shelter : Clarity',
    width: 150,
  },
  {
    field: 'Service: Financial Assistance Service - Bus Pass : Clarity',
    width: 150,
  },
  {
    field: 'Service: CaseManagement : Clarity',
    width: 150,
  },
  {
    field: 'Service: Laundry : Clarity',
    width: 150,
  },
  {
    field: 'Preferred language : Relevant',
    width: 150,
  },
  {
    field: 'Next appointment date : Relevant',
    width: 150,
  },
  {
    field: 'Next appointment provider : Relevant',
    width: 150,
  },
  {
    field: 'Next appointment type : Relevant',
    width: 150,
  },
  {
    field: 'Latest visit date : Relevant',
    width: 150,
  },
  {
    field: 'Risk scores : Relevant',
    width: 150,
  },
  {
    field: 'Care gaps : Relevant',
    width: 150,
  },
  {
    field: 'Quality measure warnings : Relevant',
    width: 150,
  },
  {
    field: 'Veteran : Clarity',
    width: 150,
  },
  {
    field: 'Unique Identifier : Clarity',
    width: 150,
  },
  {
    field: 'CIN : PHP : Care Gaps',
    width: 150,
  },
  {
    field: 'MRN : Relevant',
    width: 150,
  },
  {
    field: 'Source: ECW',
    width: 150,
  },
  {
    field: 'Source: Clarity',
    width: 150,
  },
  {
    field: 'Source: Whole Person Care',
    width: 150,
  },
  {
    field: 'Source: Partnership Health Plan',
    width: 150,
  },
  {
    field: 'Conflict',
    width: 150,
  },
  {
    field: 'Veteran : Relevant',
    width: 150,
  },
  {
    field: 'Veteran : Combined',
    width: 150,
  },
  {
    field: 'Last Name : Safety Net',
    width: 150,
  },
  {
    field: 'First Name : Safety Net',
    width: 150,
  },
  {
    field: 'Date Of Birth : Safety Net',
    width: 150,
  },
  {
    field: 'Sex : Safety Net',
    width: 150,
  },
  {
    field: 'Sexual Orientation : Safety Net',
    width: 150,
  },
  {
    field: 'Race : Safety Net',
    width: 150,
  },
  {
    field: 'Ethnicity : Safety Net',
    width: 150,
  },
  {
    field: 'Language : Safety Net',
    width: 150,
  },
  {
    field: 'Insurance : Safety Net',
    width: 150,
  },
  {
    field: 'Housing Status : Safety Net',
    width: 150,
  },
  {
    field: 'Work Status : Safety Net',
    width: 150,
  },
  {
    field: 'Veteran : Safety Net',
    width: 150,
  },
  {
    field: 'Source: Safety Net',
    width: 150,
  },
]
