import { useQueryClient } from '@tanstack/react-query'
import { useApi } from '../../providers/ApiProvider'
import { promiseToaster, useToast } from '../../providers/Toast'
import { MeetingsResponse } from './typing'

const MEETINGS_API_BASE = '/api/meetings/'

const useMeetingsApi = () => {
  const api = useApi()
  const { showToast } = useToast()
  const queryClient = useQueryClient()

  const getAllMeetings = async (skip: number = 0, limit: number = 100) => {
    const data = await api.get<MeetingsResponse[]>(MEETINGS_API_BASE, {
      params: {
        skip,
        limit,
      },
    })
    return data.data
  }

  const getMeetingById = async (id: string) => {
    const data = await api.get<MeetingsResponse>(MEETINGS_API_BASE + id)
    return data.data
  }

  const getMyMeetings = async () => {
    const data = await api.get<MeetingsResponse[]>(`${MEETINGS_API_BASE}my`)
    return data.data
  }

  const updateMeetingNotes = promiseToaster(
    async (id: string, updatedData: MeetingsResponse, show: boolean) => {
      const data = await api.put<MeetingsResponse>(
        MEETINGS_API_BASE + id,
        updatedData
      )
      queryClient.refetchQueries({
        queryKey: ['meeting-data', id],
      })
      queryClient.refetchQueries({
        queryKey: ['my-meetings'],
      })
      return data.data
    },
    'Meeting notes saved',
    'Unable to save metting notes',
    showToast
  )

  const formatPage = promiseToaster(
    async (meeting_id: string, template: string, page_id: string) => {
      const data = await api.put<{ data: string }>(
        `${MEETINGS_API_BASE}${meeting_id}/format`,
        {
          template,
          page_id,
        }
      )
      queryClient.refetchQueries({
        queryKey: ['meeting-data', meeting_id],
      })
      return data.data
    },
    'Meeting page formatted',
    '',
    showToast
  )

  return {
    getAllMeetings,
    getMeetingById,
    getMyMeetings,
    updateMeetingNotes,
    formatPage,
  }
}

export default useMeetingsApi
