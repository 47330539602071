import DeleteForever from '@mui/icons-material/DeleteForever'
import RestoreFromTrash from '@mui/icons-material/RestoreFromTrash'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import usePagesApi from './api'

interface MenuProps {
  anchorEl: any
  handleClose: () => void
  doc_id?: string
  deleted: boolean
}

const Menu: FC<MenuProps> = ({ anchorEl, handleClose, doc_id, deleted }) => {
  const { deletePage, restorePage } = usePagesApi()
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  return (
    <MuiMenu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 2,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {deleted ? (
        <>
          <MenuItem
            dense
            onClick={() => {
              if (doc_id) {
                restorePage(doc_id, true).then(() => {
                  navigate('/pages')
                })
              }
            }}
          >
            <ListItemIcon color="error">
              <RestoreFromTrash color="warning" fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
              Restore
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (doc_id) {
                deletePage(doc_id, true, true).then(() => {
                  navigate('/pages')
                })
              }
            }}
          >
            <ListItemIcon color="error">
              <DeleteForever color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
              Delete Forever
            </ListItemText>
          </MenuItem>
        </>
      ) : (
        <MenuItem
          dense
          onClick={() => {
            if (doc_id) {
              deletePage(doc_id, false, true).then(() => {
                navigate('/pages')
              })
            }
          }}
        >
          <ListItemIcon color="error">
            <DeleteForever color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
            Delete
          </ListItemText>
        </MenuItem>
      )}
    </MuiMenu>
  )
}

export default Menu
