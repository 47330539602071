import { Components, Theme } from '@mui/material'

const overrides: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiToggleButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        opacity: '0.1 !important',
      },
    },
  },
}

export default overrides
