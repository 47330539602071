import { Protect } from '@clerk/clerk-react'
import HistoryIcon from '@mui/icons-material/History'
import PlayCircle from '@mui/icons-material/PlayCircle'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Edge, Node, useEdgesState, useNodesState } from '@xyflow/react'
import { useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import useOrg from '../../hooks/useOrg'
import { useBackgroundModal } from '../../providers/BackgroundModal'
import { useToast } from '../../providers/Toast'
import JSONCanvas from '../JSONCanvas'
import useDutiesApi from './api'

const DutyDetail = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([] as Node[])
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge[])

  const { slug } = useOrg()
  const { getDutyCanvas, runDuty } = useDutiesApi()
  const { setRoot, config } = useBackgroundModal()
  const params = useParams<{ dutyId?: string }>()
  const location = useLocation()
  const navigate = useNavigate()

  const { showToast } = useToast()

  const { data } = useQuery({
    queryKey: ['duty-canvas', slug, params.dutyId],
    queryFn: () => getDutyCanvas(params.dutyId || ''),
    enabled: Boolean(params.dutyId),
  })

  const { isPending, isError, mutate } = useMutation({
    mutationKey: ['duty-canvas-run', slug, params.dutyId],
    mutationFn: () => runDuty(params.dutyId || ''),
    onSuccess: (data) => {
      if (data.patients_queued === 0) {
        showToast('No patients pending to be run on this duty', 'info')
      } else {
        showToast(
          `${data.patients_queued} calls scheduled in this duty`,
          'success'
        )
      }
    },
  })

  useEffect(() => {
    setRoot('duties')
    return () => {
      setRoot('')
    }
    // eslint-disable-next-line
  }, [])

  const isCanvas = useMemo(
    () => location.pathname.endsWith(params.dutyId || '$$$$'),
    [location.pathname, params.dutyId]
  )

  useEffect(() => {
    if (data) {
      setNodes([...data.nodes])
      setEdges([...data.edges])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  return (
    <Box sx={{ height: isCanvas ? '100vh' : config.canvasHeight }}>
      <Protect role="org:global_admin">
        <Box
          sx={{
            position: 'absolute',
            top: 80,
            right: 10,
            zIndex: 1,
            boxShadow: 1,
            borderRadius: '8px',
          }}
        >
          <IconButton onClick={() => mutate()} disabled={isPending || isError}>
            <PlayCircle color={'success'} />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: 128,
            right: 10,
            zIndex: 1,
            boxShadow: 1,
            borderRadius: '8px',
          }}
        >
          <IconButton onClick={() => navigate('history')} title="Run History">
            <HistoryIcon color={'info'} />
          </IconButton>
        </Box>
      </Protect>
      <JSONCanvas
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        showControls={isCanvas}
        showMap={isCanvas}
        viewHeight={isCanvas ? '100vh' : config.canvasHeight}
      />
      <Outlet />
    </Box>
  )
}

export default DutyDetail
