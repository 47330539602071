import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useApi } from '../../providers/ApiProvider'
import { DataType } from '../TableEditor/db.typing'
import {
  formatHeaderName,
  getColumnType,
  getDefaultWidth,
} from '../TableEditor/util'

const API_BASE_URL = '/api/dynamic/'

interface DynamicDefinition {
  column_definitions: {
    column_name: string
    data_type: string
    is_nullable: 'YES' | 'NO'
  }[]
  count: number
}

export interface DynamicView {
  id: string
  view_name: string
}

const useDynamicViewApi = () => {
  const api = useApi()

  const getViews = async () => {
    const response = await api.get<DynamicView[]>(`${API_BASE_URL}`)
    return response.data
  }

  const getView = async (viewId: string) => {
    const response = await api.get<DynamicView>(`${API_BASE_URL}${viewId}`)
    return response.data
  }

  const getViewData = async (
    viewId: string,
    page: number,
    pageSize: number
  ) => {
    const skip = page * pageSize
    const limit = pageSize
    const response = await api.get<any[]>(`${API_BASE_URL}${viewId}/data`, {
      params: { skip, limit },
    })
    return response.data
  }

  const getViewDefinition = async (viewId: string) => {
    const response = await api.get<DynamicDefinition>(
      `${API_BASE_URL}${viewId}/def`
    )
    const columnInfo = response.data.column_definitions
    if (columnInfo) {
      const generatedColumns: GridColDef[] = columnInfo.map((column) => {
        const columnDef: GridColDef = {
          field: column.column_name,
          headerName: formatHeaderName(column.column_name),
          width: getDefaultWidth(column.data_type as DataType),
          cellClassName: 'small-cell',
          editable:
            column.column_name !== 'id' && column.column_name !== 'created_at',
          type: getColumnType(column.data_type as DataType) as any,
          valueGetter:
            getColumnType(column.data_type as DataType) === 'dateTime'
              ? (e: any) => new Date(e.value)
              : undefined,
          valueFormatter:
            getColumnType(column.data_type as DataType) === 'dateTime'
              ? (e: any) => dayjs(e.value).format('ddd, MMM D (h:mm A)')
              : undefined,
        }

        if (column.data_type === 'boolean') {
          columnDef.type = 'boolean'
        }

        return columnDef
      })

      return {
        columns: generatedColumns,
        count: response.data.count,
        error: '',
      }
    } else {
      return {
        columns: [],
        count: 0,
        error: 'No definition found',
      }
    }
  }

  return {
    getViews,
    getView,
    getViewData,
    getViewDefinition,
  }
}

export default useDynamicViewApi
