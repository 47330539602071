import {
  BlockSchema,
  InlineContentSchema,
  PartialBlock,
  StyleSchema,
} from '@blocknote/core'
import { useApi } from '../../providers/ApiProvider'

export interface RootResponse {
  id: string
  created_at: string
  org_id: string[]
  blocks: PartialBlock<
    NoInfer<BlockSchema>,
    NoInfer<InlineContentSchema>,
    NoInfer<StyleSchema>
  >[]
}

const API_BASE = '/api/help-center/'

const useHelpCenterApi = () => {
  const api = useApi()

  const getHelpCenter = async () => {
    const data = await api.get<RootResponse>(`${API_BASE}`)
    return data.data
  }

  const saveHelpCenter = async (blocks: any[]) => {
    const data = await api.put<RootResponse>(`${API_BASE}`, { blocks })
    return data.data
  }

  return {
    getHelpCenter,
    saveHelpCenter,
  }
}

export default useHelpCenterApi
