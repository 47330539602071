import Tooltip from '@mui/material/Tooltip'
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'

interface CustomTooltipProps {
  title: string | ReactNode
  children: ReactElement
  styles?: any
  placement?: string
}

const CustomTooltip = ({ title, children }: CustomTooltipProps) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef<HTMLElement>()

  const compareSize = () => {
    let value =
      textElementRef && textElementRef.current
        ? textElementRef.current.scrollWidth >
          textElementRef.current.clientWidth
        : false
    setIsOverflow(value)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)
    return () => {
      window.removeEventListener('resize', compareSize)
    }
  }, [title])

  return (
    <Tooltip
      ref={textElementRef}
      title={title}
      disableHoverListener={!isOverflowed}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
