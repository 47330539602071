import { Protect, useOrganization, useUser } from '@clerk/clerk-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'
import { FC, useEffect } from 'react'
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import ActionItems from '../ActionItems'
import CallLogs from '../CallLogs'
import RouterModal from '../common/RouterModal'
import Duties from '../Duties'
import DutyDetail from '../Duties/DutyDetail'
import History from '../Duties/History'
import DynamicView from '../DynamicView'
import FloatingLayout from '../Floating'
import Helpcenter from '../Helpcenter'
import Home from '../Home'
import Individual from '../Individual'
import Journey from '../Individual/Journey'
import MeetingPage from '../MeetingPage'
import Meetings from '../Meetings'
import MyData from '../MyData'
import Reports from '../MyData/Reports'
import NotFoundPage from '../NotFound'
import Outreach from '../Outreach'
import CallDetail from '../Outreach/CallDetailFlow'
import CallLog from '../Outreach/CallLog'
import Page from '../Page'
import PromptDetail from '../PromptScript/PromptDetail'
import TableEditor from '../TableEditor'
import WorkflowAutomation from '../Workflow'
import AnimatedFlow from '../Workflow/Animated'

/* The code `declare global { interface Window { Atlas: any } }` is declaring a new property `Atlas` on
the global `Window` object in TypeScript. By doing this, you are telling the TypeScript compiler
that the `Window` object will have a property called `Atlas` of type `any`. This is useful when you
want to extend global objects in TypeScript without causing compilation errors. */
declare global {
  interface Window {
    Atlas: any
  }
}

/* `const queryClient = new QueryClient()` is creating a new instance of the `QueryClient` class
provided by the `react-query` library. This `queryClient` instance is used to manage queries and
cache data in your application. It acts as a central store for managing data fetching and caching
logic, allowing you to easily interact with external data sources and handle data fetching in a
declarative way within your React components. */
const queryClient = new QueryClient()

/**
 * The `App` component in TypeScript React uses various hooks to track user and organization data and
 * renders different routes based on the user's interactions.
 */
const App = () => {
  const posthog = usePostHog()
  const location = useLocation()
  const { user } = useUser()
  const { organization } = useOrganization()

  useEffect(() => {
    if (posthog && user && organization) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.id, {
        email: user?.primaryEmailAddress?.emailAddress,
        name: user?.fullName,
      })
      posthog?.group('company', organization?.id)
      window.Atlas.call('identify', {
        userId: user?.id,
        name: user?.fullName,
        email: user?.primaryEmailAddress?.emailAddress,
      })
    }
  }, [posthog, user, organization])

  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview')
    }
  }, [location, posthog])

  return (
    <>
      <FloatingLayout />
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path={''} element={<Home />} />
          <Route path={'duties'} element={<Outlet />}>
            <Route path={':dutyId'} element={<DutyDetail />}>
              {/* Every route that needs a modal popup must be wrapped around like this */}
              <Route path={'prompt/:key'} element={<RouterModal />}>
                <Route path={''} element={<></>} />
                <Route path={':id'} element={<PromptDetail />} />
              </Route>
              <Route path={'history'} element={<RouterModal />}>
                <Route path={''} element={<History />} />
              </Route>
            </Route>
          </Route>
          <Route path={'*'} element={<RouterModal />}>
            <Route path={'my-data'} element={<Outlet />}>
              <Route path={''} element={<MyData />} />
              <Route path={':id'} element={<Reports />} />
            </Route>
            <Route path={'view'} element={<Outlet />}>
              <Route path={':id'} element={<DynamicView />} />
            </Route>
            <Route path={'my-meetings'} element={<Meetings my={true} />}>
              <Route path={':id'} element={<MeetingPage />} />
            </Route>
            <Route path={'pages'} element={<Outlet />}>
              <Route path={''} element={<></>} />
              <Route path={':id'} element={<Page />} />
            </Route>
            <Route path={'meetings'} element={<Meetings my={false} />}>
              <Route path={':id'} element={<MeetingPage />} />
            </Route>
            <Route path={'duties'} element={<Outlet />}>
              <Route path={''} element={<Duties />} />
            </Route>
            <Route path={'help-center'} element={<Helpcenter />} />
            <Route path={'actions'} element={<ActionItems />} />
            <Route path={'call-logs'} element={<CallLogs />} />
            <Route
              path={'phone'}
              element={<TableEditor tableName="phone_manager" />}
            />
            <Route path={'workflow'} element={<AnimatedFlow />} />
            <Route path={'workflows'} element={<Outlet />}>
              <Route path="" element={<></>} />
              <Route path={':id'} element={<WorkflowAutomation />} />
            </Route>
            <Route path={'individual'} element={<Outlet />}>
              <Route path="" element={<Individual />} />
              <Route path={':indvidual_id'} element={<Journey />} />
            </Route>
            <Route
              path={'begin-outreach'}
              element={
                <Protect role="org:global_admin">
                  <Outlet />
                </Protect>
              }
            >
              <Route path={''} element={<Outreach />} />
              <Route path={':campaign-id'} element={<Outlet />}>
                <Route path={''} element={<CallLog />} />
                <Route path={'detail/:call-id'} element={<CallDetail />} />
              </Route>
            </Route>
            <Route path={'*'} element={<NotFoundPage />} />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

/**
 * The CoreApp component renders the App component wrapped in QueryClientProvider and BrowserRouter
 * components.
 * @returns The `CoreApp` component is being returned. It wraps the `App` component with
 * `BrowserRouter` and `QueryClientProvider` components.
 */
const CoreApp: FC = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default CoreApp
