import * as MiniIcons from '@heroicons/react/16/solid'
import * as SmallIcons from '@heroicons/react/20/solid'
import * as RegOtulineIcons from '@heroicons/react/24/outline'
import * as RegSolidIcons from '@heroicons/react/24/solid'
import { FC, useMemo } from 'react'

type IconName =
  | keyof typeof MiniIcons
  | keyof typeof SmallIcons
  | keyof typeof RegOtulineIcons
  | keyof typeof RegSolidIcons

interface HeroIconProps extends React.SVGProps<SVGSVGElement> {
  size?: 'micro' | 'mini' | 'regular'
  variant?: 'solid' | 'outline'
  iconName: IconName
}

const getIconBase = (
  size: HeroIconProps['size'],
  variant: HeroIconProps['variant']
) => {
  if (size === 'micro') {
    return MiniIcons
  } else if (size === 'mini') {
    return SmallIcons
  } else {
    return variant === 'solid' ? RegSolidIcons : RegOtulineIcons
  }
}

const HeroIcon: FC<HeroIconProps> = ({
  size = 'regular',
  variant = 'outline',
  iconName,
  ...rest
}) => {
  const IconBase = useMemo(() => getIconBase(size, variant), [size, variant])
  const Icon = useMemo(
    () => IconBase[iconName as keyof typeof IconBase],
    [IconBase, iconName]
  )
  if (!Icon) {
    return <></>
  }
  return <Icon {...rest} />
}

export default HeroIcon
