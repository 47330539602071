import AddIcon from '@mui/icons-material/Add'
import {
  Alert,
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

interface LiquidEditorProps {
  text: string
  setText: (value: string) => void
  allowedVariables?: string[] | null
}

const LiquidEditor: React.FC<LiquidEditorProps> = ({
  text = '',
  setText,
  allowedVariables = null,
}) => {
  const [variables, setVariables] = useState<string[]>([])
  const [invalidVariables, setInvalidVariables] = useState<string[]>([])
  const textEditorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const variableRegex = /\{\{\s*([^}]+)\s*\}\}/g
    const matches = [...text.matchAll(variableRegex)]
    const foundVars = matches.map((match) => match[1].trim())

    const uniqueVars = [...new Set(foundVars)]

    if (allowedVariables) {
      const invalid = uniqueVars.filter((v) => !allowedVariables.includes(v))
      const valid = uniqueVars.filter((v) => allowedVariables.includes(v))
      setInvalidVariables(invalid)
      setVariables(valid)
    } else {
      setInvalidVariables([])
      setVariables(uniqueVars)
    }
  }, [text, allowedVariables])

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value
    setText(newText)
  }

  const insertVariable = (varName: string) => {
    const textarea = document.getElementById(
      'liquid-editor'
    ) as HTMLTextAreaElement
    if (textarea) {
      const start = textarea.selectionStart
      const end = textarea.selectionEnd
      const newText = `${text.slice(0, start)}{{ ${varName} }}${text.slice(end)}`
      setText(newText)
    }
  }

  const createVariable = () => {
    const varName = prompt('Enter variable name:')
    if (!varName || !varName.trim()) return

    const trimmedName = varName.trim()
    if (allowedVariables && !allowedVariables.includes(trimmedName)) {
      alert('This variable name is not in the allowed list')
      return
    }

    insertVariable(trimmedName)
  }

  return (
    <Box sx={{ maxWidth: 'lg', width: '100%' }}>
      <>
        {allowedVariables && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Allowed variables: {allowedVariables.join(', ')}
          </Typography>
        )}

        {invalidVariables.length > 0 && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Invalid variables detected: {invalidVariables.join(', ')}
          </Alert>
        )}

        <TextField
          multiline
          fullWidth
          minRows={4}
          value={text}
          onChange={handleTextChange}
          placeholder="Enter your template text here..."
          variant="outlined"
          ref={textEditorRef}
          sx={{ mb: 2 }}
        />

        <Box sx={{ mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Typography variant="subtitle2">Variables</Typography>
            {(!allowedVariables || allowedVariables.length > 0) && (
              <Button
                size="small"
                variant="contained"
                sx={{ display: 'none' }}
                startIcon={<AddIcon />}
                onClick={createVariable}
              >
                Add Variable
              </Button>
            )}
          </Stack>

          <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
            {(allowedVariables || variables).map((variable) => (
              <Chip
                key={variable}
                label={variable}
                onClick={() => insertVariable(variable)}
                sx={{ mb: 1 }}
              />
            ))}
          </Stack>
        </Box>

        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Preview
          </Typography>
          <Paper variant="outlined" sx={{ p: 2, backgroundColor: '#fafafa' }}>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {text || 'Preview will appear here'}
            </Typography>
          </Paper>
        </Box>
      </>
    </Box>
  )
}

export default LiquidEditor
