import { keyframes, styled } from '@mui/material'
import Box from '@mui/material/Box'

const bounce = keyframes`
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`

const LoaderWrapper = styled(Box)({
  display: 'inline-block',
  position: 'relative',
  width: '80px',
  height: '80px',
})

const Dot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '33px',
  width: '13px',
  height: '13px',
  borderRadius: '50%',
  background: theme.palette.primary.main,
  animation: `${bounce} 1.2s infinite ease-in-out`,
  '&:nth-of-type(1)': { left: '8px', animationDelay: '-0.24s' },
  '&:nth-of-type(2)': { left: '32px', animationDelay: '-0.12s' },
  '&:nth-of-type(3)': { left: '56px', animationDelay: '0s' },
}))

// Loader component that contains three bouncing dots
const Loader = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <LoaderWrapper>
        <Dot />
        <Dot />
        <Dot />
      </LoaderWrapper>
    </Box>
  )
}

export default Loader
