import Timeline from '@mui/icons-material/Timeline'
import MuiLink from '@mui/material/Link'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import TableEditor from '../TableEditor'

const hiddenColumns = [
  {
    column_name: 'First Name : Clarity',
  },
  {
    column_name: 'Last Name : Clarity',
  },
  {
    column_name: 'Address Type : Clarity',
  },
  {
    column_name: 'Address : Clarity',
  },
  {
    column_name: 'City : Clarity',
  },
  {
    column_name: 'State : Clarity',
  },
  {
    column_name: 'Zipcode : Clarity',
  },
  {
    column_name: 'Service: Succesfully Housed : Clarity',
  },
  {
    column_name: 'Service: Shower : Clarity',
  },
  {
    column_name: 'Service: SSI Referral : Clarity',
  },
  {
    column_name: 'Service: Legal Aid Referral : Clarity',
  },
  {
    column_name: 'Service: Ritter CM Bus Pass : Clarity',
  },
  {
    column_name: 'Service: Clothing Closet : Clarity',
  },
  {
    column_name: 'Service: Pantry : Clarity',
  },
  {
    column_name: 'Service: Jonathan Place Shelter : Clarity',
  },
  {
    column_name: 'Service: Financial Assistance Service - Bus Pass : Clarity',
  },
  {
    column_name: 'Service: CaseManagement : Clarity',
  },
  {
    column_name: 'Service: Laundry : Clarity',
  },
  {
    column_name: 'Name : Clarity',
  },
  {
    column_name: 'Date Of Birth : Clarity',
  },
  {
    column_name: 'Gender : Clarity',
  },
  {
    column_name: 'Race : Clarity',
  },
  {
    column_name: 'Veteran : Clarity',
  },
  {
    column_name: 'First Name : Relevant',
  },
  {
    column_name: 'Last Name : Relevant',
  },
  {
    column_name: 'MRN : Relevant',
  },
  {
    column_name: 'Date Of birth : Relevant',
  },
  {
    column_name: 'Date of birth reformatted : Relevant',
  },
  {
    column_name: 'Age : Relevant',
  },
  {
    column_name: 'Preferred phone : Relevant',
  },
  {
    column_name: 'Home phone : Relevant',
  },
  {
    column_name: 'Email address : Relevant',
  },
  {
    column_name: 'Address : Relevant',
  },
  {
    column_name: 'Zipcode : Relevant',
  },
  {
    column_name: 'Is deceased? : Relevant',
  },
  {
    column_name: 'Is inactive? : Relevant',
  },
  {
    column_name: 'Gender : Relevant',
  },
  {
    column_name: 'Sexual orientation : Relevant',
  },
  {
    column_name: 'Assigned female at birth : Relevant',
  },
  {
    column_name: 'Race : Relevant',
  },
  {
    column_name: 'Ethnicity : Relevant',
  },
  {
    column_name: 'Preferred language : Relevant',
  },
  {
    column_name: 'Primary location : Relevant',
  },
  {
    column_name: 'Primary caregiver : Relevant',
  },
  {
    column_name: 'Primary insurance : Relevant',
  },
  {
    column_name: 'Insurance subscriber number : Relevant',
  },
  {
    column_name: 'Next appointment date : Relevant',
  },
  {
    column_name: 'Next appointment provider : Relevant',
  },
  {
    column_name: 'Next appointment type : Relevant',
  },
  {
    column_name: 'Latest visit date : Relevant',
  },
  {
    column_name: 'Risk scores : Relevant',
  },
  {
    column_name: 'Care gaps : Relevant',
  },
  {
    column_name: 'Quality measure warnings : Relevant',
  },
  {
    column_name: 'UDS insurance : Relevant',
  },
  {
    column_name: 'CIN : PHP : Care Gaps',
  },
  {
    column_name: 'First Name : PHP : Care Gaps',
  },
  {
    column_name: 'Last Name : PHP : Care Gaps',
  },
  {
    column_name: 'Phone : PHP : Care Gaps',
  },
  {
    column_name: 'Gender : PHP : Care Gaps',
  },
  {
    column_name: 'Date Of Birth : PHP : Care Gaps',
  },
  {
    column_name: 'New Member : PHP : Care Gaps',
  },
  {
    column_name: 'Denominator : Cervical Cancer  : PHP : Care Gaps',
  },
  {
    column_name: 'Numerator : Cervical Cancer  : PHP : Care Gaps',
  },
  {
    column_name: 'Denominator : Diabetes - HbA1C  : PHP : Care Gaps',
  },
  {
    column_name: 'Numerator : Diabetes - HbA1C  : PHP : Care Gaps',
  },
  {
    column_name: 'Denominator : Breast Cancer  : PHP : Care Gaps',
  },
  {
    column_name: 'Numerator : Breast Cancer  : PHP : Care Gaps',
  },
  {
    column_name: 'Denominator :  High Blood Pressure : PHP : Care Gaps',
  },
  {
    column_name: 'Numerator :  High Blood Pressure : PHP : Care Gaps',
  },
  {
    column_name: 'Name : Whole Person Care',
  },
  {
    column_name: 'Gender : Whole Person Care',
  },
  {
    column_name: 'Race : Whole Person Care',
  },
  {
    column_name: 'Ethnicity : Whole Person Care',
  },
  {
    column_name: 'Age : Whole Person Care',
  },
  {
    column_name: 'Sexual Orientation : Whole Person Care',
  },
  {
    column_name: 'Insurance : Whole Person Care',
  },
  {
    column_name: 'Status : Whole Person Care',
  },
  {
    column_name: 'First Name : Whole Person Care',
  },
  {
    column_name: 'Last Name : Whole Person Care',
  },
  {
    column_name: 'Unique Identifier : Clarity',
  },
  {
    column_name: 'organization',
  },
].map((e) => e.column_name)

const Individual: FC = () => {
  return (
    <TableEditor
      tableName="patient_info"
      readonly
      hideFields={hiddenColumns}
      columnSortPreference={['Universal Patient ID']}
      columOverrides={[
        {
          field: 'Universal Patient ID',
          headerName: 'Universal Patient ID',
          renderCell: (params) => {
            return (
              <MuiLink
                component={Link}
                to={params.row['Universal Patient ID']}
                display={'flex'}
                alignItems={'center'}
                gap={1}
                sx={{
                  color: 'text.secondary',
                  textDecorationColor: 'text.secondary',
                }}
                underline="hover"
              >
                {params.row['Universal Patient ID']}{' '}
                <Timeline
                  fontSize="small"
                  sx={{ fontSize: '14px', height: '14px', width: '14px' }}
                />
              </MuiLink>
            )
          },
        },
      ]}
    />
  )
}

export default Individual
