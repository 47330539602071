import { Protect } from '@clerk/clerk-react'
import Box from '@mui/material/Box'
import {
  Background,
  Controls,
  MiniMap,
  ReactFlow,
  ReactFlowProvider,
} from '@xyflow/react'
import CustomNode from '../../CustomNode'
import { initialEdges, initialNodes } from './constant'

const nodeTypes = {
  custom: CustomNode,
}

const CallDetailFlow: React.FC = () => {
  return (
    <Protect role="org:global_admin">
      <Box sx={{ width: '100%', height: 'calc(100vh - 102px)' }}>
        <ReactFlow
          nodes={initialNodes}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          fitView
        >
          <Controls />
          <MiniMap />
          <Background />
        </ReactFlow>
      </Box>
    </Protect>
  )
}

const CallDetail: React.FC = () => {
  return (
    <ReactFlowProvider>
      <CallDetailFlow />
    </ReactFlowProvider>
  )
}

export default CallDetail
