import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'
import useOrg from '../../hooks/useOrg'
import Grid from '../common/Grid'
import GridContainer from '../common/GridContainer'
import Loader from '../Loader'
import useCallLogsApi from './api'
import { cols } from './column-def'
import { millisToTimeString, parseDate } from './util'

const hideList = ['assistant_id', 'call_id', 'cost']

const CallLogs: FC = () => {
  const navigate = useNavigate()
  const { getCalls } = useCallLogsApi()
  const { isHelpcareHq, slug } = useOrg()

  const { data, isError, isLoading } = useQuery({
    queryKey: ['table-data', 'call_logs', slug],
    queryFn: getCalls,
  })

  const columns = useMemo(
    () =>
      isHelpcareHq ? cols : cols.filter((e) => !hideList.includes(e.field)),
    [isHelpcareHq]
  )

  const rows = useMemo(() => {
    return (data || []).map((e) => {
      const data: any = { ...e }
      data['duration'] = millisToTimeString(e.duration || 0)
      parseDate(e, data, 'start_date_time', 'start')
      parseDate(e, data, 'end_date_time', 'end')
      return data
    })
  }, [data])

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <Box>Error</Box>
  }

  return (
    <GridContainer>
      <Grid
        checkboxSelection
        disableRowSelectionOnClick={true}
        slots={{
          noRowsOverlay: () => (
            <Box display={'flex'} justifyContent={'center'} pt={3}>
              <Typography variant="subtitle2" color="text.primary">
                To see your meting notes be sure to invite
                notetaker.hanna@helpcare.ai to your meetings.
              </Typography>
            </Box>
          ),
        }}
        columns={columns}
        rows={rows}
        rowHeight={40}
        pageSizeOptions={[25, 50, 100]}
        onCellClick={(e) => {
          if (e.field === 'title') {
            navigate(`${e.id}`)
          }
        }}
      />
    </GridContainer>
  )
}

export default CallLogs
