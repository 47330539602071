import Box from '@mui/material/Box'
import { useQuery } from '@tanstack/react-query'
import { ReactFlowProvider, useEdgesState, useNodesState } from '@xyflow/react'
import { FC } from 'react'
import { useParams } from 'react-router'
import Loader from '../../Loader'
import CoreWorkflow from '../../Workflow/Core'
import useIndividualJourney from './api'

const JourneyView: FC<{ nodesR: any[]; edgesR: any[] }> = ({
  edgesR,
  nodesR,
}) => {
  const [nodes, setNodes] = useNodesState(nodesR)
  const [edges, setEdges] = useEdgesState(edgesR)

  return (
    <Box
      sx={{
        height: 'calc(100vh - 120px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CoreWorkflow
        edges={edges}
        nodes={nodes}
        setEdges={setEdges}
        setNodes={setNodes}
      />
    </Box>
  )
}

const Journey: FC = () => {
  const params = useParams()

  const { getJourney } = useIndividualJourney()

  const { data, isLoading, error } = useQuery({
    queryKey: ['individual', params.indvidual_id],
    enabled: !!params.indvidual_id,
    queryFn: () => getJourney(params.indvidual_id || ''),
  })

  console.log(data, isLoading, error)

  if (!data || isLoading) {
    return <Loader />
  }
  return (
    <ReactFlowProvider>
      <JourneyView nodesR={data.nodes} edgesR={data.edges} />
    </ReactFlowProvider>
  )
}

export default Journey
