import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridColDef } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import AnimatedProgress from '../../common/ReportsProgress'
import TableEditor from '../../TableEditor'
import useTablesApi from '../../TableEditor/api'

interface DataAccordionProps {
  expanded: string | false
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  panelId: string
  title: string
  tableName: string
  progressTableName: string
  readonlyFields: string[]
  columnOverrides: GridColDef[]
}

interface ProgressScore {
  rows: Array<{
    completion_score: number
  }>
}

const DataAccordion: React.FC<DataAccordionProps> = ({
  expanded,
  handleChange,
  panelId,
  title,
  tableName,
  progressTableName,
  readonlyFields,
  columnOverrides,
}) => {
  const { fetchData } = useTablesApi()
  const tableData = useQuery<ProgressScore>({
    queryKey: ['table-data', progressTableName],
    queryFn: () =>
      fetchData(progressTableName, { items: [] }, [], {
        pageSize: 1,
        page: 0,
      }),
    enabled: Boolean(progressTableName),
  })

  if (!tableData?.data) return null

  return (
    <Accordion
      expanded={expanded === panelId}
      onChange={handleChange(panelId)}
      sx={{
        display: 'grid',
        '& .MuiCollapse-root': {
          overflow: 'auto',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panelId}bh-content`}
        id={`${panelId}bh-header`}
      >
        <Box display="flex" alignItems="center" width="100%" gap={1}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ width: '20%', flexShrink: 0 }}
          >
            {title} - Missing Data
          </Typography>
          <AnimatedProgress
            value={(tableData.data?.rows[0]?.completion_score || 0) * 100}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TableEditor
          hideHeader
          tableName={tableName}
          readonly={false}
          readonlyFields={readonlyFields}
          style={{ height: '500px', width: '100%' }}
          columOverrides={columnOverrides}
          onEditComplete={() => {
            setTimeout(() => {
              tableData.refetch()
            }, 100)
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default DataAccordion
