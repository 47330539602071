import { useAuth } from '@clerk/clerk-react'
import { GridPaginationModel } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { FC, useState } from 'react'
import { useParams } from 'react-router'
import Grid from '../common/Grid'
import GridContainer from '../common/GridContainer'
import Loader from '../Loader'
import { defaultPageSize } from '../TableEditor/const'
import useDynamicViewApi from './api'

interface DynamicViewProps {
  style?: {
    height?: string
    width?: string
  }
  getRowId?: (e: any) => any
}
const DynamicView: FC<DynamicViewProps> = ({ style = {}, getRowId }) => {
  const { getViewData, getViewDefinition } = useDynamicViewApi()
  const params = useParams<{ id: string }>()

  const { has } = useAuth()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: defaultPageSize,
  })
  const editPermission =
    has?.({ permission: 'org:feature:db_table_edit' }) || false
  const rows = useQuery({
    queryKey: ['dynamic', paginationModel, params.id],
    queryFn: () =>
      getViewData(
        params.id || '',
        paginationModel.page,
        paginationModel.pageSize
      ),
    enabled: !!params.id,
  })
  const cols = useQuery({
    queryKey: ['dynamic-def', params.id],
    queryFn: () => getViewDefinition(params.id || ''),
    enabled: !!params.id,
  })

  if (rows.isLoading || cols.isLoading) {
    return <Loader />
  }

  if (cols.error) {
    return <div>Error</div>
  }

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel)
  }

  return (
    <GridContainer height={style.height} width={style.width}>
      <Grid
        disableRowSelectionOnClick={true}
        checkboxSelection
        disableColumnFilter={false}
        rowHeight={40}
        rows={rows.data || []}
        getRowId={getRowId}
        columns={cols.data?.columns || []}
        loading={cols.isLoading || rows.isLoading}
        pageSizeOptions={[25, 50, 100]}
        isCellEditable={(e) => {
          if (e.field === 'id') {
            return false
          }
          return editPermission
        }}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        initialState={{
          pagination: { paginationModel: { pageSize: defaultPageSize } },
        }}
        rowCount={cols.data?.count || 0}
        columnVisibilityModel={{
          created_at: false,
        }}
      />
    </GridContainer>
  )
}

export default DynamicView
