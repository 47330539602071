import { Protect } from '@clerk/clerk-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'
import ErrorBoundary from '../ErrorBoundary'
import Loader from '../Loader'
import usePagesApi from '../PageTree/api'
import PageComponent from './Page'

export default function Page() {
  const params = useParams()

  const { getPageById } = usePagesApi()

  const { data, isLoading } = useQuery({
    queryKey: ['page-data', params.id],
    queryFn: () => getPageById(params.id || ''),
  })

  if (isLoading) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }
  if (!data) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }

  return (
    <Protect
      permission="org:feature:read_pages"
      fallback={
        <Typography variant="h2">
          You are not allowed to access this page
        </Typography>
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          '& .bn-default-styles': {
            fontFamily: 'Lato,sans-serif',
          },
        }}
        key={params.id}
      >
        <Box maxWidth={'lg'} width={'100%'}>
          <ErrorBoundary>
            <PageComponent data={data} />
          </ErrorBoundary>
        </Box>
      </Box>
    </Protect>
  )
}
