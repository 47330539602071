import {
  OrganizationSwitcher as OrgSwitcher,
  Protect,
  useOrganization,
} from '@clerk/clerk-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

const OrganizationSwitcher = () => {
  const { organization } = useOrganization()
  const location = useLocation()
  const navigate = useNavigate()
  const showAiCanvas = useMemo(
    () => location.pathname === '/' || location.pathname.startsWith('/duties/'),
    [location.pathname]
  )
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Protect
        role="org:global_admin"
        fallback={<Typography variant="body1">{organization?.name}</Typography>}
      >
        <Box
          sx={{
            '& .cl-button': {
              '& .cl-avatarBox': {
                display: 'none',
              },
              color: 'text.primary',
            },
            alignItems: 'center',
            display: 'flex',
            maxHeight: '20px',
          }}
        >
          <OrgSwitcher hidePersonal hideSlug />
        </Box>
      </Protect>
      {showAiCanvas && (
        <Typography
          variant="subtitle3"
          onClick={() => navigate('/')}
          sx={{ color: 'text.secondary', pl: 2.5, cursor: 'pointer' }}
        >
          AI Canvas
        </Typography>
      )}
    </Box>
  )
}

export default OrganizationSwitcher
