import { useApi } from '../../../providers/ApiProvider'

interface ReportsResponse {
  id: string
  title: string
  created_at: string
  source: 'table' | 'dashboard'
  table_name: string
}

export interface ReportDetail extends ReportsResponse {
  editable: boolean
  readonlyFields: string[]
  score_view?: string
}

const API_BASE = '/api/reports/'

const useReportsApi = () => {
  const api = useApi()

  const getAllReports = async (skip: number = 0, limit: number = 100) => {
    const data = await api.get<ReportsResponse[]>(API_BASE, {
      params: {
        skip,
        limit,
      },
    })
    return data.data
  }

  const getReportById = async (id: string) => {
    const data = await api.get<ReportDetail>(API_BASE + id)
    return data.data
  }

  return {
    getAllReports,
    getReportById,
  }
}

export default useReportsApi
