import { useApi } from '../../providers/ApiProvider'
import { Root } from '../Home/api'
import { edgeMapper, nodeMapper } from '../JSONCanvas/util'

const API_BASE_URL = '/api/duties/'

export interface DutyDetail {
  id: string
  created_at: string
  Name: string
  agent: string
  patients?: string[]
  call_prompt_id: string
  org_id: string
}

export interface DutyHistory {
  id: string
  run_by: string
  ritter_duties: Pick<DutyDetail, 'Name'>
}

const useDutiesApi = () => {
  const api = useApi()

  const getDuties = async () => {
    const data = await api.get<DutyDetail[]>(API_BASE_URL)
    return data.data
  }

  const getDutyDetails = async (id: string) => {
    const data = await api.get<DutyDetail>(`${API_BASE_URL}${id}`)
    return data.data
  }

  const getDutyCanvas = async (id: string) => {
    const data = await api.get<Root>(`${API_BASE_URL}${id}/canvas`)
    return {
      nodes: (data?.data?.nodes || []).map(nodeMapper) || [],
      edges: (data?.data?.edges || []).map(edgeMapper) || [],
    }
  }

  const runDuty = async (id: string) => {
    const data = await api.post<{ patients_queued: number }>(
      `${API_BASE_URL}${id}/run`
    )
    return data.data
  }

  const getDutyHistory = async (id: string, limit: number = 5) => {
    const data = await api.get<DutyHistory[]>(`${API_BASE_URL}${id}/history`)
    return data.data
  }

  return {
    getDuties,
    getDutyDetails,
    getDutyCanvas,
    runDuty,
    getDutyHistory,
  }
}

export default useDutiesApi
