import { useApi } from '../../providers/ApiProvider'
import { CallDetails } from './typing'

export interface CallLogsResponse {
  rows: CallRecord[]
}

export interface CallRecord {
  id: string
  created_at: string
  organization: any
  call_id: string
  start_date_time?: string
  end_date_time?: string
  duration?: number
  type: string
  cost: string
  cost_denomination: string
  end_reason?: string
  call_outcome: any
  call_reason: any
  callee_number?: string
  callee_name: any
  callee_email: any
  kpis?: string
  action_items: any
  notes: any
  url?: string
  assistant_id: string
  first_name?: string
  last_name?: string
  transcript?: string
  duty_name: string | null
}

interface CallRow {
  call_id: string
  created_at: string
  org_id: string
  duty_id: string | null
  duty_name: string | null
  response: CallDetails
  first_name?: string
  last_name?: string
}

const mapCallRow = (callRow: CallRow): CallRecord => {
  const call = callRow.response
  const duration =
    new Date(call.endedAt).getTime() - new Date(call.startedAt).getTime()
  const row: CallRecord = {
    id: call.id,
    call_id: call.id,
    start_date_time: call.startedAt,
    end_date_time: call.endedAt,
    type: call.type,
    cost: call.cost,
    duration: duration,
    cost_denomination: 'USD',
    end_reason: call.endedReason,
    callee_number: call.customer?.number,
    kpis: call.analysis?.summary,
    url: call.recordingUrl,
    assistant_id: call.assistantId,
    created_at: callRow.created_at,
    organization: callRow.org_id,
    duty_name: callRow.duty_name,
    call_outcome: null,
    callee_name: null,
    callee_email: null,
    action_items: null,
    call_reason: null,
    notes: null,
    first_name: callRow.first_name,
    last_name: callRow.last_name,
    transcript: call.transcript,
  }
  return row
}

const useCallLogsApi = () => {
  const api = useApi()

  const getCalls = async (): Promise<CallRecord[]> => {
    const data = await api.get<CallRow[]>(`/api/calls/`, {
      params: { limit: 1000 },
    })

    const rows = data.data || []
    const mappedRows = rows.map(mapCallRow)
    return mappedRows
  }

  return {
    getCalls,
  }
}

export default useCallLogsApi
