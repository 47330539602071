import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core'
import { BlockNoteView } from '@blocknote/mantine'
import { useCreateBlockNote } from '@blocknote/react'
import { Protect } from '@clerk/clerk-react'
import Link from '@mui/icons-material/Link'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { FC, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import debounce from '../../util/debounce'
import Loader from '../Loader'
import useActionsApi, { RootResponse } from './api'

const schema = BlockNoteSchema.create({
  blockSpecs: {
    //first pass all the blockspecs from the built in, default block schema
    ...defaultBlockSpecs,
    // disable blocks you don't want
    file: undefined as any,
    audio: undefined as any,
    image: undefined as any,
    video: undefined as any,
  },
})

interface ActionItemBlockProps {
  data: RootResponse
}
const ActionItemBlock: FC<ActionItemBlockProps> = ({ data }) => {
  const editor = useCreateBlockNote({
    //@ts-ignore
    initialContent: data.blocks || [{ type: 'heading', content: '' }],
    schema,
  })

  const { palette } = useTheme()
  const { saveActionItem } = useActionsApi()

  const date = useMemo(
    () => dayjs(new Date(data.action_time)),
    [data.action_time]
  )

  const saveAction = () => {
    debounce(() => saveActionItem(data.id, editor.document))()
  }

  return (
    <>
      <MuiLink
        component={RouterLink}
        color="inherit"
        to={`/meetings/${data.meeting_id}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          textDecoration: 'none',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ ml: 6, my: 1 }}
          color={'text.secondary'}
        >
          {`${date.format('ddd, MMM D')}`}
        </Typography>
        <Link sx={{ color: 'text.secondary', height: 18 }} />
      </MuiLink>
      <Protect
        condition={(has: any) =>
          has({ permission: 'org:feature:write_meeting_notes' })
        }
        fallback={
          <BlockNoteView
            style={{ width: '100%' }}
            editor={editor}
            theme={palette.mode}
            editable={false}
          />
        }
      >
        <BlockNoteView
          onChange={() => {
            saveAction()
          }}
          style={{ width: '100%' }}
          editor={editor}
          theme={palette.mode}
        />
      </Protect>

      <Divider />
    </>
  )
}

const ActionItems = () => {
  const { getMyActionItems } = useActionsApi()
  const { data, isLoading } = useQuery({
    queryKey: ['my-actions'],
    queryFn: getMyActionItems,
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <Protect
      permission="org:feature:read_meeting_notes"
      fallback={
        <Typography variant="h2">
          You are not allowed to access this page
        </Typography>
      }
    >
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <Box maxWidth={'lg'}>
          <Typography variant="h4" sx={{ ml: 6, mb: 2 }}>
            My Actions
          </Typography>
          <Divider />
          {data?.map((e, index) => <ActionItemBlock key={index} data={e} />)}
        </Box>
      </Box>
    </Protect>
  )
}

export default ActionItems
