import { SignIn, useAuth } from '@clerk/clerk-react'
import Box from '@mui/material/Box'
import homeImage from '../images/home.png'
import DotsBackground from './Animated'
import Logo from './icons/LogoWithText'
import Loader from './Loader'

const NoLogin = () => {
  const { isLoaded, isSignedIn } = useAuth()
  return (
    <DotsBackground>
      <Box
        sx={{
          position: 'absolute',
          top: 15,
          left: 15,
        }}
      >
        <Logo height={30} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!isLoaded && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader />
            </Box>
          )}
          {!isSignedIn && isLoaded && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <SignIn />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            p: 2,
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component={'img'}
            src={homeImage}
            alt="home Image"
            sx={({ palette }) => ({
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
              borderRadius: '20px',
              filter: palette.mode === 'dark' ? 'grayscale(0.2)' : undefined,
            })}
          />
        </Box>
      </Box>
    </DotsBackground>
  )
}
export default NoLogin
