import { Box } from '@mui/material'
import {
  BackgroundProps,
  BackgroundVariant,
  ReactFlowState,
  useStore,
} from '@xyflow/react'
import cc from 'classcat'
import { CSSProperties, memo, useRef } from 'react'
import { shallow } from 'zustand/shallow'

export const containerStyle: CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}
// import { type BackgroundProps, BackgroundVariant } from './types';
// import { type ReactFlowState } from '../../types';

const defaultSize = {
  [BackgroundVariant.Dots]: 1,
  [BackgroundVariant.Lines]: 1,
  [BackgroundVariant.Cross]: 6,
}

const selector = (s: ReactFlowState) => ({
  transform: s.transform,
  patternId: `pattern-${s.rfId}`,
})

type DotPatternProps = {
  radius: number
  className?: string
}

export function DotPattern({ className }: DotPatternProps) {
  return (
    <>
      <Box
        component={'path'}
        className={cc(['react-flow__background-pattern', 'dots', className])}
        fillRule="evenodd"
        clipRule="evenodd"
        sx={({ palette }) => ({
          fill: palette.background.paper,
        })}
        d="M2.86049 0L2.84986 0.0287319C2.84873 0.0317741 2.8476 0.0348146 2.84647 0.0378535C2.78186 0.211257 2.70651 0.379213 2.62122 0.540933C2.21841 1.30467 1.59373 1.92935 0.829996 2.33215C0.668275 2.41745 0.50032 2.4928 0.326916 2.55741C0.323877 2.55854 0.320837 2.55967 0.317794 2.5608L0.289062 2.57143L0.317794 2.58206C0.320837 2.58319 0.323877 2.58432 0.326916 2.58545C0.50032 2.65006 0.668275 2.72541 0.829996 2.8107C1.59373 3.21351 2.21841 3.83819 2.62122 4.60192C2.70651 4.76365 2.78186 4.9316 2.84647 5.105C2.8476 5.10804 2.84873 5.11108 2.84986 5.11413L2.86049 5.14286L2.87112 5.11413C2.87225 5.11108 2.87338 5.10804 2.87451 5.105C2.93912 4.9316 3.01447 4.76364 3.09976 4.60192C3.50257 3.83819 4.12725 3.21351 4.89099 2.8107C5.05271 2.72541 5.22066 2.65006 5.39407 2.58545C5.39711 2.58432 5.40015 2.58319 5.40319 2.58206L5.43192 2.57143L5.40319 2.5608C5.40015 2.55967 5.39711 2.55854 5.39407 2.55741C5.22066 2.4928 5.05271 2.41745 4.89099 2.33216C4.12725 1.92935 3.50257 1.30467 3.09976 0.540933C3.01447 0.379213 2.93912 0.211257 2.87451 0.0378534C2.87338 0.0348146 2.87225 0.0317741 2.87112 0.0287319L2.86049 0Z"
      />
      <Box
        component={'path'}
        sx={({ palette }) => ({
          fill: palette.text.disabled,
        })}
        className={cc(['react-flow__background-pattern', className])}
        d="M2.86049 0L2.87112 0.0287319C3.3052 1.20181 4.23011 2.12672 5.40319 2.5608L5.43192 2.57143L5.40319 2.58206C4.23011 3.01614 3.3052 3.94104 2.87112 5.11413L2.86049 5.14286L2.84986 5.11413C2.41578 3.94104 1.49088 3.01614 0.317794 2.58206L0.289062 2.57143L0.317794 2.5608C1.49088 2.12672 2.41578 1.20181 2.84986 0.0287319L2.86049 0Z"
      />
    </>
  )
}

function BackgroundComponent({
  id,
  variant = BackgroundVariant.Dots,
  // only used for dots and cross
  gap = 20,
  // only used for lines and cross
  size,
  offset = 0,
  style,
  className,
  patternClassName,
}: BackgroundProps) {
  const ref = useRef<SVGSVGElement>(null)
  const { transform, patternId } = useStore(selector, shallow)
  const patternSize = size || defaultSize[variant]
  const gapXY: [number, number] = Array.isArray(gap) ? gap : [gap, gap]
  const scaledGap: [number, number] = [
    gapXY[0] * transform[2] || 1,
    gapXY[1] * transform[2] || 1,
  ]
  const scaledSize = patternSize * transform[2]
  const offsetXY: [number, number] = Array.isArray(offset)
    ? offset
    : [offset, offset]

  const patternDimensions: [number, number] = scaledGap
  const scaledOffset: [number, number] = [
    offsetXY[0] * transform[2] || 1 + patternDimensions[0] / 2,
    offsetXY[1] * transform[2] || 1 + patternDimensions[1] / 2,
  ]

  const _patternId = `${patternId}${id ? id : ''}`

  return (
    <Box
      component={'svg'}
      className={cc(['react-flow__background', className])}
      sx={({ palette }) =>
        ({
          ...style,
          ...containerStyle,
          '--xy-background-color-props': palette.background.paper,
          '--xy-background-pattern-color-props': palette.text.disabled,
        }) as CSSProperties
      }
      ref={ref}
      data-testid="rf__background"
    >
      <pattern
        id={_patternId}
        x={transform[0] % scaledGap[0]}
        y={transform[1] % scaledGap[1]}
        width={scaledGap[0]}
        height={scaledGap[1]}
        patternUnits="userSpaceOnUse"
        patternTransform={`translate(-${scaledOffset[0]},-${scaledOffset[1]})`}
      >
        <DotPattern radius={scaledSize / 2} className={patternClassName} />
      </pattern>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={`url(#${_patternId})`}
      />
    </Box>
  )
}

BackgroundComponent.displayName = 'CustomBackground'

const CustomBackground = memo(BackgroundComponent)
export default CustomBackground
