import { ClerkProvider, useAuth } from '@clerk/clerk-react'
import { dark } from '@clerk/themes'
import { useTheme } from '@mui/material/styles'
import { useEffect } from 'react'
import CoreApp from './components/App'
import NoLogin from './components/NoLogin'
import ApiProvider, { useApi } from './providers/ApiProvider'
import BackgroundModalProvider from './providers/BackgroundModal'
import ThemeToggleProvider from './providers/ThemeToggle'
import ToastProvider from './providers/Toast'

const Root = () => {
  const { isSignedIn } = useAuth()
  const api = useApi()

  /* The `useEffect` hook in the provided code snippet is used to set a global variable `window.api` to
  the value of the `api` object obtained from the `useApi` hook. this is strictly for local testing */
  useEffect(() => {
    //@ts-ignore
    window.api = api
  }, [api])

  if (isSignedIn) {
    return <CoreApp />
  }
  return <NoLogin />
}

const AppWithTheme = () => {
  const { palette, typography } = useTheme()
  return (
    <ClerkProvider
      afterSignOutUrl={'/'}
      appearance={{
        baseTheme: palette.mode === 'dark' ? dark : undefined,
        variables: {
          fontFamily: typography.fontFamily,
          colorText: palette.text.primary,
          colorTextSecondary: palette.text.secondary,
          colorDanger: palette.error.main,
          colorSuccess: palette.success.main,
          colorWarning: palette.warning.main,
          colorPrimary: palette.text.primary,
        },
      }}
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || ''}
    >
      <ApiProvider>
        <Root />
      </ApiProvider>
    </ClerkProvider>
  )
}

function App() {
  return (
    <ThemeToggleProvider>
      <BackgroundModalProvider>
        <ToastProvider>
          <AppWithTheme />
        </ToastProvider>
      </BackgroundModalProvider>
    </ThemeToggleProvider>
  )
}

export default App
