import MuiLink from '@mui/material/Link'
import { GridColDef } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import useOrg from '../../hooks/useOrg'
import { useBackgroundModal } from '../../providers/BackgroundModal'
import Grid from '../common/Grid'
import GridContainer from '../common/GridContainer'
import Loader from '../Loader'
import useDutiesApi from './api'

const cols: GridColDef[] = [
  {
    field: 'Name',
    headerName: 'Name',
    cellClassName: 'small-cell',
    width: 350,
    renderCell: (e) => (
      <MuiLink
        component={Link}
        color="text.secondary"
        to={`/duties/${e.row.id}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {e.row['Name']}
      </MuiLink>
    ),
  },
  {
    field: 'patients',
    headerName: 'Patients',
    cellClassName: 'small-cell',
    width: 150,
    valueGetter: (params) => ((params as string[]) || []).length,
  },
]

const Duties = () => {
  const { slug } = useOrg()
  const { getDuties } = useDutiesApi()
  const { setRoot } = useBackgroundModal()
  const { data, isLoading, isError } = useQuery({
    queryKey: ['duties', slug],
    queryFn: getDuties,
  })

  useEffect(() => {
    setRoot('')
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <Loader />
  if (isError) return <div>Error loading duties</div>

  return (
    <GridContainer>
      <Grid columns={cols} rows={data || []} />
    </GridContainer>
  )
}
export default Duties
