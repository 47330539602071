import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { PropsWithChildren } from 'react'

const PostHogProviderWrapper = ({ children }: PropsWithChildren) => {
  if (window.location.host !== 'app.helpcare.ai') return <>{children}</>
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
    capture_pageview: false,
  })

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

export default PostHogProvider
