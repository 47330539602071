import Box from '@mui/material/Box'
import React, { FC, PropsWithChildren } from 'react'

type Position =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'middle-left'
  | 'middle-center'
  | 'middle-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'

interface FloatingWidgetProps {
  dockPosition: Position // Docking position of the widget
  children: React.ReactNode // Content to render inside the widget
}

const positionStyles: Record<Position, object> = {
  'top-left': { top: 8, left: 8, transform: 'translate(0%, 0%)' },
  'top-center': { top: 8, left: '50%', transform: 'translate(-50%, 0%)' },
  'top-right': { top: 8, right: 8, transform: 'translate(0%, 0%)' },
  'middle-left': { top: '50%', left: 8, transform: 'translate(0%, -50%)' },
  'middle-center': {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  'middle-right': { top: '50%', right: 8, transform: 'translate(0%, -50%)' },
  'bottom-left': { bottom: 8, left: 8, transform: 'translate(0%, 0%)' },
  'bottom-center': {
    bottom: 8,
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  'bottom-right': { bottom: 8, right: 8, transform: 'translate(0%, 0%)' },
}

const FloatingWidget: FC<PropsWithChildren<FloatingWidgetProps>> = ({
  dockPosition,
  children,
}) => {
  return (
    <Box
      sx={({ palette, zIndex }) => ({
        position: 'fixed',
        ...positionStyles[dockPosition],
        zIndex: zIndex.modal, // Ensure the widget floats above other elements
        backgroundColor: palette.background.paper,
        boxShadow: 1,
        borderRadius: 2,
        padding: 1,
      })}
    >
      {children}
    </Box>
  )
}

export default FloatingWidget
