import Box from '@mui/material/Box'
import Grid2 from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { ReactFlow, useEdgesState, useNodesState } from '@xyflow/react'
import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router'
import Loader from '../../Loader'
import TableEditor from '../../TableEditor'
import useTablesApi from '../../TableEditor/api'
import ColorNodeComponent from '../../Workflow/nodes/ColorNode'
import TextNodeComponent from '../../Workflow/nodes/TextNode'
import useReportsApi from '../Reports/api'
import { currencyFormat } from '../Reports/util'
import {
  caseManagementColumn,
  ecwColumn,
  pantryColumn,
  qualityStatisticsColumn,
  safetyNetColumn,
  updColumn,
  wpcColumn,
} from './const'
import DataAccordion from './DataAccordion'

const DataSourceCanvas: FC = () => {
  const { fetchData } = useTablesApi()

  const tableData = useQuery({
    queryKey: ['table-data', 'app_ritter_patient_data_source'],
    queryFn: () =>
      fetchData('app_ritter_patient_data_source', { items: [] }, [], {
        pageSize: 1,
        page: 0,
      }),
    enabled: Boolean('app_ritter_patient_data_source'),
  })

  const nodeTypes = {
    text: TextNodeComponent,
    color: ColorNodeComponent,
  }

  const initialEdges: any[] = [
    {
      source: '92975c20-c995-4a22-98b3-fe2a6a56e711',
      target: '70b708ba-2401-4e1a-85d9-d2e2b64024c1',
      workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      id: '7566689e-d8bc-4fc2-b7e5-2fba39aee97a',
      animated: true,
    },
    {
      source: '0e12e007-741e-44df-a913-2e55bedaeefa',
      target: '70b708ba-2401-4e1a-85d9-d2e2b64024c1',
      workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      id: '3eef9551-4237-494c-ad0e-84c31dbddc3a',
      animated: true,
    },
    {
      source: '7fcdc39a-1460-4333-8645-271b18052bc4',
      target: '70b708ba-2401-4e1a-85d9-d2e2b64024c1',
      workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      id: '920908d5-4ea1-4e17-862e-d99f7509c367',
      animated: true,
    },
    {
      source: '5a88eaf7-732d-4692-9d7a-ca9f2d2259df',
      target: '70b708ba-2401-4e1a-85d9-d2e2b64024c1',
      workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      id: 'd2e8c0f8-6dd7-404a-bc0f-641c120c65e2',
      animated: true,
    },
    {
      source: '00662e0c-fc1e-4d02-a8c9-51c543d69ca9',
      target: '70b708ba-2401-4e1a-85d9-d2e2b64024c1',
      workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      id: 'fbbf5080-2e3c-4bea-805f-f4c9b6ff7192',
      animated: true,
    },
  ]
  const defaultViewport = { x: 0, y: 0, zoom: 1.5 }
  const [nodes, setNodes] = useNodesState<any>([])
  const [edges] = useEdgesState(initialEdges)

  useMemo(() => {
    const initialNodes: any[] = [
      {
        type: 'color',
        data: {
          icon: 'CreditScore',
          type: 'text',
          bgColor: '#99e7de',
          label: 'Safety Net',
          description: tableData.data?.rows[0].safety_net,
          workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
        },
        position: {
          x: -380,
          y: 180,
        },
        id: '7fcdc39a-1460-4333-8645-271b18052bc4',
        workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      },
      {
        type: 'color',
        data: {
          icon: 'CreditScore',
          type: 'text',
          bgColor: '#f9cbb8',
          label: 'Services : Clarity',
          description: tableData.data?.rows[0].services_clarity,
          workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
        },
        position: {
          x: -400,
          y: 0,
        },
        id: '0e12e007-741e-44df-a913-2e55bedaeefa',
        workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      },
      {
        type: 'color',
        data: {
          icon: 'CreditScore',
          type: 'text',
          bgColor: '#94ccf9',
          label: 'Whole Person Care',
          description: tableData.data?.rows[0].whole_person_care,
          workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
        },
        position: {
          x: -420,
          y: 520,
        },
        id: '00662e0c-fc1e-4d02-a8c9-51c543d69ca9',
        workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      },
      {
        type: 'color',
        data: {
          icon: 'CreditScore',
          type: 'text',
          bgColor: 'primary.light',
          label: 'ECW (Medical + BH)',
          description: tableData.data?.rows[0].ecw,
          workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
        },
        position: {
          x: -420,
          y: -160,
        },
        id: '92975c20-c995-4a22-98b3-fe2a6a56e711',
        workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      },
      {
        type: 'color',
        data: {
          icon: 'CreditScore',
          type: 'text',
          bgColor: '#fce09d',
          label: 'Partnership Health',
          description: tableData.data?.rows[0].partnership_health,
          workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
        },
        position: {
          x: -420,
          y: 340,
        },
        id: '5a88eaf7-732d-4692-9d7a-ca9f2d2259df',
        workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      },
      {
        type: 'color',
        data: {
          icon: 'CreditScore',
          type: 'text',
          label: 'Total Unduplicated Patients',
          description: tableData.data?.rows[0].total_unduplicated_patients,
          workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
        },
        position: {
          x: 660,
          y: 220,
        },
        id: '70b708ba-2401-4e1a-85d9-d2e2b64024c1',
        workflow_id: 'ce183f4a-15b5-4738-bd2a-968113f400e4',
      },
    ]
    setNodes(initialNodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData.data?.rows])

  if (!tableData || !tableData.data?.rows) {
    return <></>
  }

  return (
    <>
      <Box sx={{ width: '100%', height: '500px' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          fitView
          snapToGrid
          defaultViewport={defaultViewport}
          snapGrid={[20, 20]}
          panOnScroll={false}
          zoomOnScroll={false}
          panOnDrag={false}
          nodesDraggable={false}
          nodesConnectable={false}
          preventScrolling={false}
        ></ReactFlow>
      </Box>
    </>
  )
}

const CareGapsData: FC = () => {
  const { fetchData } = useTablesApi()

  const tableData = useQuery({
    queryKey: ['table-data', 'app_ritter_care_gaps'],
    queryFn: () =>
      fetchData('app_ritter_care_gaps', { items: [] }, [], {
        pageSize: 1,
        page: 0,
      }),
  })

  if (!tableData) {
    return <></>
  }

  return (
    <>
      <Box marginTop={10} marginBottom={10}>
        <Box
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          gap={1}
          textAlign={'center'}
          marginBottom={6}
        >
          <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
            🚑 Care Gaps Data
          </Typography>
        </Box>

        <Grid2
          container
          rowSpacing={8}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          marginBottom={6}
          paddingY={10}
          paddingX={6}
          sx={{
            '--Grid-borderWidth': '1px',
            border: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider',
          }}
        >
          <Grid2 size={6} textAlign={'center'}>
            <Typography variant="h6" marginBottom={3}>
              ⛑️ Care Gaps Open (Total)
            </Typography>
            <Typography variant="h3">
              {currencyFormat(tableData.data?.rows[0].care_gaps_open)}
            </Typography>
          </Grid2>
          <Grid2 size={6} textAlign={'center'}>
            <Typography variant="h6" marginBottom={3}>
              💵 Unrealized Care Gap Revenue (@$300/appt)
            </Typography>
            <Typography variant="h3">
              {currencyFormat(tableData.data?.rows[0].care_gaps_revenue, 'USD')}
            </Typography>
          </Grid2>
          <Grid2 size={6} textAlign={'center'}>
            <Typography variant="h6" marginBottom={3}>
              📣 Unreached Partnership Health Patients (Capitated)
            </Typography>
            <Typography variant="h3">
              {currencyFormat(
                tableData.data?.rows[0].unreached_partnership_health_patients
              )}
            </Typography>
          </Grid2>
          <Grid2 size={6} textAlign={'center'}>
            <Typography variant="h6" marginBottom={3}>
              💵 Unrealized Revenue (@$300/appt)
            </Typography>
            <Typography variant="h3">
              {currencyFormat(
                tableData.data?.rows[0].unrealized_revenue,
                'USD'
              )}
            </Typography>
          </Grid2>
        </Grid2>

        <TableEditor
          tableName={'app_ritter_care_gaps_analysis'}
          readonly={false}
          readonlyFields={['Care Gap Type']}
          style={{ height: '400px' }}
          enableCellColor={true}
          columOverrides={[
            {
              field: '% Of Care Gaps Closed',
              headerName: '% Of Care Gaps Closed',
              renderCell: (params) => {
                return `${params.value} %`
              },
            },
          ]}
        />
      </Box>
    </>
  )
}

const Dashboards: FC = () => {
  const params = useParams()

  const { getReportById } = useReportsApi()

  const { data, isLoading } = useQuery({
    queryKey: ['report-data', params.id],
    queryFn: () => getReportById(params.id || ''),
  })

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  if (isLoading) {
    return <Loader />
  }

  if (!data) {
    return <></>
  }

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        gap={1}
        textAlign={'center'}
        marginBottom={6}
        marginTop={6}
      >
        <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
          {data.title}
        </Typography>
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        gap={1}
        marginBottom={6}
        marginTop={6}
      >
        <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
          Data Source View
        </Typography>
      </Box>
      <DataSourceCanvas />
      <TableEditor
        tableName={data.table_name}
        readonly={!data.editable}
        readonlyFields={data.readonlyFields || []}
        getRowId={(row: any) => row.id}
        enableCellColor={true}
        style={{ height: '400px' }}
        columOverrides={qualityStatisticsColumn}
      />
      <Box
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        gap={1}
        textAlign={'center'}
        marginBottom={6}
        marginTop={6}
      >
        <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
          🏬 Department Data
        </Typography>
      </Box>

      <Box>
        <DataAccordion
          expanded={expanded}
          handleChange={handleChange}
          panelId="panel1"
          title="Case Management"
          tableName="app_ritter_case_management"
          progressTableName="app_ritter_case_management_progress_score"
          readonlyFields={[
            'Universal Patient ID',
            'First Name',
            'Last Name',
            'Clarity ID',
          ]}
          columnOverrides={caseManagementColumn}
        />
        <DataAccordion
          expanded={expanded}
          handleChange={handleChange}
          panelId="panel2"
          title="Pantry"
          tableName="app_ritter_pantry"
          progressTableName="app_ritter_pantry_progress_score"
          readonlyFields={[
            'Universal Patient ID',
            'First Name',
            'Last Name',
            'Clarity ID',
          ]}
          columnOverrides={pantryColumn}
        />
        <DataAccordion
          expanded={expanded}
          handleChange={handleChange}
          panelId="panel3"
          title="ECW (Medical + BH)"
          tableName="app_ritter_ecw"
          progressTableName="app_ritter_ecw_progress_score"
          readonlyFields={[
            'Universal Patient ID',
            'First Name',
            'Last Name',
            'Relevant ID',
          ]}
          columnOverrides={ecwColumn}
        />
        <DataAccordion
          expanded={expanded}
          handleChange={handleChange}
          panelId="panel4"
          title="Whole Person Care"
          tableName="app_ritter_wpc"
          progressTableName="app_ritter_wpc_progress_score"
          readonlyFields={['Universal Patient ID', 'First Name', 'Last Name']}
          columnOverrides={wpcColumn}
        />
        <DataAccordion
          expanded={expanded}
          handleChange={handleChange}
          panelId="panel5"
          title="Safety Net"
          tableName="app_ritter_safety_net"
          progressTableName="app_ritter_safety_net_progress_score"
          readonlyFields={['Universal Patient ID', 'First Name', 'Last Name']}
          columnOverrides={safetyNetColumn}
        />
      </Box>
      <CareGapsData />
      <Box
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        gap={1}
        textAlign={'center'}
        marginBottom={6}
        marginTop={6}
      >
        <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
          🌎 Universal Patient Data
        </Typography>
      </Box>
      <TableEditor
        tableName={'ritter_master_hz'}
        hideHeader
        showId
        readonly={false}
        readonlyFields={['Universal Patient ID', 'First Name', 'Middle Name', 'Last Name']}
        hideFields={['services', 'appointments']}
        style={{ height: '500px' }}
        columOverrides={updColumn}
      />
    </>
  )
}

export default Dashboards
