import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Handle, Position } from '@xyflow/react'
import Action from './Action'
import { CustomNodeComponentProps } from './typing'

const handleStyle: React.CSSProperties = {
  width: '12px',
  height: '12px',
  background: '#555',
  border: '2px solid white',
  opacity: 0,
}

const CustomNode: React.FC<CustomNodeComponentProps> = ({ data }) => {
  const action = data.action || {}
  const { top, bottom } = action
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          ...handleStyle,
          visibility: data.visible ? 'visible' : 'hidden',
        }}
      />
      <Box sx={{ opacity: data.visible ? 1 : 0, transition: 'opacity 0.5s' }}>
        <Card
          variant="elevation"
          sx={{ maxWidth: 150, p: 0, m: 0 }}
          elevation={0}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: 0,
              m: 0,
            }}
          >
            <Box
              sx={{
                height: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {top && <Action {...top} location="top" />}
            </Box>
            {data.icon}
            <Typography variant="body3" component="div">
              {data.label}
            </Typography>
            <Typography variant="subtitle3">{data.caption}</Typography>{' '}
            <Box
              sx={{
                height: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {bottom && <Action {...bottom} location="bottom" />}
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Handle
        type="source"
        position={Position.Right}
        style={{
          ...handleStyle,
          visibility: data.visible ? 'visible' : 'hidden',
        }}
      />
    </>
  )
}

export default CustomNode
