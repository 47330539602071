import { Protect } from '@clerk/clerk-react'
import AddIcon from '@mui/icons-material/Add'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { TreeItem2Label } from '@mui/x-tree-view/TreeItem2'
import { FC, PropsWithChildren, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import usePagesApi from './api'
import Menu from './TreeMenu'

interface CustomLabelProps {
  expandable?: boolean
  doc_id?: string
  deleted: boolean
  showOptions?: boolean
  icon?: ReactNode
}

const CustomLabel: FC<PropsWithChildren<CustomLabelProps>> = ({
  children,
  doc_id,
  expandable,
  deleted,
  showOptions,
  icon,
  ...other
}) => {
  const { createPage } = usePagesApi()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <TreeItem2Label {...other}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: 0.25,
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {icon}

          <CustomTooltip title={children}>
            <Typography
              variant="subtitle2"
              sx={{ color: 'text.secondary' }}
              noWrap
            >
              {children}
            </Typography>
          </CustomTooltip>
        </Box>
        {showOptions && (
          <Protect permission="org:feature:write_pages">
            <Box display={'flex'} gap={1}>
              <IconButton
                size="small"
                sx={{
                  height: 16,
                  width: 16,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  handleClick(e)
                }}
              >
                <MoreHoriz
                  height={16}
                  width={16}
                  sx={{ color: 'text.secondary' }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                handleClose={handleClose}
                deleted={deleted}
                doc_id={doc_id}
              />
              {!deleted && (
                <IconButton
                  size="small"
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                  onClick={(e) => {
                    createPage(doc_id, true).then((e) => {
                      navigate('/pages/' + e.id)
                    })
                  }}
                >
                  <AddIcon
                    height={16}
                    width={16}
                    sx={{ color: 'text.secondary' }}
                  />
                </IconButton>
              )}
            </Box>
          </Protect>
        )}
      </Box>
    </TreeItem2Label>
  )
}

export default CustomLabel
